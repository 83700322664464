import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SocialsElement, SocialsElementKey } from 'src/app/features/socials/socials.component';

@Component({
  selector: 'app-modal-url-socials-tooltip',
  templateUrl: './modal-url-socials-tooltip.component.html',
  styleUrl: './modal-url-socials-tooltip.component.scss'
})
export class ModalUrlSocialsTooltipComponent implements OnInit{

  socialElementKey: SocialsElementKey | 'preview' = null;

  socialElementKeyEnum: typeof SocialsElementKey = SocialsElementKey;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    if (this.navParams?.data?.socialElementKey) {
      this.socialElementKey = this.navParams.data.socialElementKey;
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
