/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable max-len */
import { Component, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum BadgeStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent {
  @Input() status: BadgeStatus = BadgeStatus.ACTIVE;
  @Input() disabled: boolean = false;
  @Output() onStatusChanged = new BehaviorSubject<BadgeStatus>(null);

  badgeStatusEnum: typeof BadgeStatus = BadgeStatus;

  onBadgeClick() {
    if (this.disabled) return;

    if (this.status === BadgeStatus.ACTIVE) {
      this.status = BadgeStatus.INACTIVE;
    } else {
      this.status = BadgeStatus.ACTIVE;
    }
    this.onStatusChanged.next(this.status);
  }
}
