/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { OrderService } from '@mocli/MocliCommonLib';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, debounceTime, firstValueFrom, Subject, Subscription } from 'rxjs';
import { CodeHuntCode } from 'src/app/data/models/CodeHunt';
import { SimpleQuiz } from 'src/app/data/models/SimpleQuiz';
import { InputTextareaComponent } from 'src/app/features/input/input-textarea/input-textarea.component';
import { ModalConfirmComponent } from 'src/app/features/shared/modal/modal-confirm/modal-confirm.component';
import { ModalSubExpConfiguratorComponent } from 'src/app/features/shared/modal/modal-sub-exp-configurator/modal-sub-exp-configurator.component';
import { v4 as uuidv4 } from 'uuid';
import { ConfiguratorWithList } from '../../models/configurator-with-list';
import { ModalAddCategoryComponent } from 'src/app/features/shared/modal/modal-add-category/modal-add-category.component';
import { StepsService } from 'src/app/features/steps/steps.service';
import { BadgeStatus } from 'src/app/features/shared/status-badge/status-badge.component';


export class CodeHuntCodeUI {
    code: string;
    hint: string;
    location: string;
    subExpConfig: any[];
    status: BadgeStatus;
    category?: string;

    get quizLength() {
        return this.subExpConfig?.length ?? 0;
    }
}

export interface HistoryContentEditor {
    config: any;
    metadata: HistoryMetaData;
}

export interface HistoryMetaData {
    code?: string;
    inputName?: string;
    key?: string;

    lang?: string;
    categories?: string[];
}


@Component({
    selector: 'app-codehunt-content-editor',
    templateUrl: './codehunt-content-editor.component.html',
    styleUrls: ['./codehunt-content-editor.component.scss']
})
export class CodehuntContentEditorComponent implements OnInit, OnDestroy {
    @ViewChild('codeHuntQuizContainer') codeHuntQuizContainer: ElementRef<HTMLDivElement>;


    @Input() orderId: number = null;
    @Input() config: any = null;
    @Input() configByLang: any = null;
    @Input() modularConfigByLang: any = null;
    @Input() codeHuntConfig: any = null;
    baseCodeHuntConfig: any = null;
    @Input() structureConfig: any = null;

    @Input() changesSubject: BehaviorSubject<boolean> = null;

    @Input() undoRedoSubject: Subject<string> = null;
    undoRedoSubjectSubscription: Subscription = null;

    @Output() autoSaving: EventEmitter<{ state: boolean; key?: 'structure' | 'content'; config?: any; language?: string }> = new EventEmitter<{ state: boolean; key?: 'structure' | 'content'; config?: any; language?: string }>();

    @Output() undoStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() redoStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() editorModeChanged: Subject<{ state: boolean; initiator: 'parent' | 'child' }> = new Subject<{ state: boolean; initiator: 'parent' | 'child' }>();

    @Input() editorMode: boolean = false;

    @Input() languages: string[] = [];
    @Input() baseLanguage: string = null;

    @Input() saveSubject: Subject<{ ref: any; nextUrl: string }>;

    currentLanguage: string = null;

    afterEditorMode: boolean = false;

    selectedCode: CodeHuntCodeUI = null;
    selectedCodeIdx: number = 0;

    codesList: CodeHuntCodeUI[] = [];
    baseCodes: CodeHuntCode[] = [];
    maxCodes: number = 20;

    codeHuntCodeStatusEnum: typeof BadgeStatus = BadgeStatus;

    contentEditorFormGroup: FormGroup = null;
    editorValueChangesSubscription: Subscription = null;
    editorValueHintChangesDebounceTimeSubscription: Subscription = null;
    editorValueLocationChangesDebounceTimeSubscription: Subscription = null;

    isFormSubmitted: boolean = false;
    isUpdating: boolean = false;

    animatingQuestions: boolean = false;

    resetInputSubject: Subject<void> = new Subject<void>();

    isUpdatingStatus: boolean = false;

    get currentCodesLength() {
        return this.codesList?.length ?? 0;
    }

    get selectedIdx() {
        if (!this.selectedCode) return 0;

        return this.codesList.findIndex(c => c.code === this.selectedCode.code);
    }

    get currentMultiCategories() {
        return this.codeHuntConfig?.multiCategories ?? [];
    }

    get hasMultiCategories() {
        return this.codeHuntConfig?.hasMultiCategories;
    }

    get languagesWithoutBase() {
        return this.languages?.filter((lang) => lang !== this.baseLanguage);
    }

    get isBaseLanguage() {
        return this.currentLanguage === this.baseLanguage;
    }

    noHistoryTemp: boolean = false;

    configHistoryStack: HistoryContentEditor[] = [];
    redoHistoryStack: HistoryContentEditor[] = [];

    @HostListener('window:keydown', ['$event'])
    async onKeyDown(event: KeyboardEvent) {
        const hasModal = await this.modalController.getTop();

        if (!hasModal && (event.metaKey || event.ctrlKey) && (event.key === 'z' || event.key === 'Z') && !event.shiftKey) {
            event.preventDefault();
            this.onUndoClick();
        }

        if (!hasModal && (event.metaKey || event.ctrlKey) && event.shiftKey && (event.key === 'z' || event.key === 'Z')) {
            event.preventDefault();
            this.onRedoClick();
        }
    }

    constructor(
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private modalController: ModalController,
        private stepsService: StepsService,
        private messageService: MessageService
    ) {

    }

    async ngOnInit() {
        // gestion des langues
        if (this.languages?.length > 0) {
            let stepIdx = this.stepsService.currentStepIdx - 2;
            if (stepIdx < 0) stepIdx = 0;

            this.currentLanguage = this.languages[stepIdx] ?? null;
            if (this.currentLanguage === null) {
                console.error('Aucune langue trouvée pour le codehunt configurator');
            }
        }

        if (this.languages?.length > 1 && this.isBaseLanguage) {
            await this.getConfigBaseLanguage();
        }

        // on set le bon codehuntConfig
        if (this.currentLanguage && this.currentLanguage !== this.baseLanguage && this.configByLang[this.currentLanguage]) {
            this.codeHuntConfig = JSON.parse(JSON.stringify(this.configByLang[this.currentLanguage]));
            this.baseCodeHuntConfig = JSON.parse(JSON.stringify(this.configByLang[this.baseLanguage]));

            this.codeHuntConfig.hasMultiCategories = this.baseCodeHuntConfig?.hasMultiCategories;
        }

        if (!this.currentLanguage) this.currentLanguage = this.baseLanguage;

        // on récupère les codes de base
        this.baseCodes = this.codeHuntConfig.baseCodes;

        this.initList();

        if (this.undoRedoSubject) {
            this.undoRedoSubjectSubscription = this.undoRedoSubject.subscribe((event: string) => {
                if (event === 'undo') {
                    this.onUndoClick();
                } else if (event === 'redo') {
                    this.onRedoClick();
                }
            });
        }

        if (this.editorModeChanged) {
            this.editorModeChanged.subscribe((event: { state: boolean; initiator: 'parent' | 'child' }) => {
                if (event?.initiator === 'child') return;

                if (!event?.state) {
                    this.editorMode = false;
                    this.afterEditorMode = false;
                    this.updatingEditorMode = true;
                    setTimeout(() => {
                        this.updatingEditorMode = false;
                    }, 500);
                } else {
                    this.editorMode = true;
                    setTimeout(() => {
                        if (this.updatingEditorMode === false) this.afterEditorMode = true;
                    }, 500);
                }
            });
        }
    }

    updatingEditorMode = false;

    ngOnDestroy() {
        if (this.undoRedoSubjectSubscription) {
            this.undoRedoSubjectSubscription.unsubscribe();
        }
    }

    async getConfigBaseLanguage() {
        try {
            const res: any = await firstValueFrom(this.orderService.getOrderConfiguration(this.orderId, this.baseLanguage));

            if (res && res?.configuration?.ModularRoot?.content && res?.configuration?.ModularRoot?.content?.length > 0) {
                const codeHuntConfig = res.configuration.ModularRoot.content.find(c => c?.CodeHunt);

                if (codeHuntConfig?.CodeHunt) {
                    this.codeHuntConfig = codeHuntConfig?.CodeHunt;
                }
            }
        } catch (error) {
            console.log('error while getting base language config', error);
        }
    }

    onAddElementClick() {
        if (!this.isBaseLanguage) return;

        this.addNewElement();
    }

    initList() {
        const codes = this.codeHuntConfig.codes;

        if (this.isBaseLanguage) {
            codes?.forEach((code) => {
                if (!this.codesList.some(c => c?.code === code?.code)) {
                    this.codesList.push({
                        code: code.code,
                        hint: code?.hint?.content,
                        location: code?.hint?.location,
                        subExpConfig: code?.subExpConfig && code?.subExpConfig?.length > 0 ? code?.subExpConfig?.map(q => q?.SimpleQuiz) : null,
                        status: code?.status,
                        quizLength: code?.subExpConfig?.length ?? 0,
                        category: code?.category
                    });
                }
            });
        } else {
            this.codeHuntConfig.baseCodes = this.baseCodeHuntConfig?.baseCodes;
            this.codeHuntConfig.totalCodes = this.baseCodeHuntConfig?.totalCodes;

            const finalCodes = [];

            this.baseCodeHuntConfig?.codes?.forEach((baseCode: CodeHuntCode) => {
                const code = codes.find(c => c.code === baseCode.code);
                const newCode: CodeHuntCodeUI = {
                    code: '',
                    hint: null,
                    location: null,
                    subExpConfig: null,
                    category: code?.category ?? null,
                    status: BadgeStatus.ACTIVE,
                    quizLength: 0
                };

                newCode.code = code?.code ?? baseCode.code;
                newCode.status = code?.status ?? baseCode?.status;

                if (code?.hint?.content) {
                    newCode.hint = code?.hint?.content;
                }

                if (baseCode?.hint?.location) {
                    newCode.location = baseCode?.hint?.location;
                }

                if (baseCode?.subExpConfig && baseCode?.subExpConfig?.length > 0) {
                    newCode.subExpConfig = code?.subExpConfig?.map(q => q?.SimpleQuiz) ?? null;
                }

                finalCodes.push(newCode);
            });

            this.codesList = JSON.parse(JSON.stringify(finalCodes));
        }


        this.maxCodes = this.codeHuntConfig.totalCodes ?? 20;

        // TODO REMOVE THIS
        // this.onEditClick(this.codesList[0]);

        // setTimeout(() => {
        //     this.onAddEditSubExpQuestionClick(1);
        // }, 500);
    }

    registerNewHistoryEvent(config: any, metadata: any, noSave: boolean = false) {
        this.configHistoryStack.push({ config, metadata });
        this.redoHistoryStack = [];
        this.undoStatus.emit(true);
        this.redoStatus.emit(false);

        if (!noSave) {
            if (metadata?.key === 'language-update') {
                this.saveContentByLang(config, metadata?.lang);
            } else {
                this.saveContent(true);
            }
        }
    }

    addNewElement() {
        let added = false;
        let newCode = null;

        const codesListTmp = JSON.parse(JSON.stringify(this.codesList));

        this.baseCodes?.forEach((code) => {
            if (!added && !this.codesList.some(c => c?.code === code?.code)) {
                newCode = {
                    code: code.code ?? null,
                    hint: code?.hint?.content ?? null,
                    location: code?.hint?.location ?? null,
                    subExpConfig: null,
                    quizLength: 0,
                    status: BadgeStatus.ACTIVE,
                };
                this.codesList.push(JSON.parse(JSON.stringify(newCode)));
                added = true;
            }
        });

        if (added) {
            this.registerNewHistoryEvent(codesListTmp, { key: 'add-element' }, true);
            this.redoHistoryStack = [];
            this.onEditClick(this.codesList[this.codesList.length - 1]);
            setTimeout(() => {
                this.saveContent(true);
            }, 200);
        }

        if (added && this.languages?.length > 1) {
            this.languagesWithoutBase.forEach(lang => {
                if (!this.configByLang[lang] || !this.configByLang[lang]?.codes) return;

                const newCodeLang = JSON.parse(JSON.stringify(newCode));

                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), { key: 'language-update', lang }, true);

                this.configByLang[lang].codes.push(newCodeLang);

                this.saveContentByLang(this.configByLang[lang].codes, lang);
            });
        }
    }

    async onStatusChanged(status: BadgeStatus, code: CodeHuntCodeUI) {
        if (!this.isBaseLanguage || !status || !code || this.isUpdatingStatus) return;

        this.isUpdatingStatus = true;

        const idx = this.codesList.findIndex(c => c.code === code.code);
        if (idx >= 0) {
            let metaData: any = null;
            if (this.selectedCode && this.selectedCode?.code) {
                metaData = { code: this.selectedCode.code };
            }
            this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), metaData, true);
            this.codesList[idx].status = status;

            if (this.languages?.length > 1) {
                this.languagesWithoutBase.forEach(lang => {
                    if (!this.configByLang[lang] || !this.configByLang[lang]?.codes || this.configByLang[lang].codes?.length <= idx) return;

                    this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), {key: 'language-update', lang}, true);

                    this.configByLang[lang].codes[idx].status = status;

                    this.saveContentByLang(this.configByLang[lang].codes, lang);
                });
            }

            await this.saveContent(true);
        }

        this.isUpdatingStatus = false;
    }

    editClickCount: number = 0;

    onEditClick(code: CodeHuntCodeUI, inputToFocus: 'hint' | 'location' = null, forceNoSaveStatus: boolean = false) {
        if (!code) return;

        // on active le mode d'update pour afficher le blur sur le contenu
        if (this.editorMode && this.selectedCode !== code) this.isUpdating = true;

        // on active le mode d'édition pour afficher le contenu et non la vue d'ensemble
        this.editorMode = true;
        setTimeout(() => {
            if (this.updatingEditorMode === false) this.afterEditorMode = true;
        }, 500);
        this.editorModeChanged.next({ state: true, initiator: 'child' });

        // système pour savoir si on est déjà en train d'afficher le blur
        this.editClickCount++;
        const editTmp = JSON.parse(JSON.stringify(this.editClickCount));

        // s'il y a eu des changements, on les save avant de changer de code (en local, par encore en db)
        if (this.changesSubject.value === true) {
            this.saveContentLocal();
        }

        this.selectedCodeIdx = this.codesList.findIndex(c => c.code === code.code);

        // scroll au top de l'élement id codehuntContentMainContainer
        const element = document.getElementById('codehuntContentMainContainer');
        if (element) {
            element.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // on attends 200ms pour attendre l'animation de blur avant de charger le contenu
        setTimeout(() => {
            // on change le code sélectionné
            this.selectedCode = code;

            // initialisation du formGroup
            if (!this.contentEditorFormGroup) this.initFormGroup(code);
            else this.updateFormGroup(code);
        }, 200);

        // on désactive le mode d'update après 400ms si on n'a pas cliqué sur un autre code
        setTimeout(() => {
            if (this.editClickCount === editTmp) {
                this.isUpdating = false;

                if (inputToFocus) {
                    if (inputToFocus === 'hint') {
                        const inputElement = document.getElementsByClassName('mInput_textarea_container')[0];
                        if (inputElement && inputElement?.children?.length > 0) {
                            (inputElement.children[0] as any).focus();
                        }
                    } else {
                        const inputElement = document.getElementById('input-' + inputToFocus);
                        inputElement.focus();
                    }
                }
            }
        }, 400);
    }

    initFormGroup(code: CodeHuntCodeUI = null) {
        // on charge le contenu du code sélectionné
        this.contentEditorFormGroup = this.formBuilder.group({
            hint: [code?.hint ?? '', [Validators.maxLength(300)]],
            location: [{value: code?.location ?? '', disabled: !this.isBaseLanguage}, [Validators.maxLength(100)]],
        });

        if (this.hasMultiCategories) {
            const category = this.codeHuntConfig.multiCategories.find(c => code?.category === c);
            this.contentEditorFormGroup.addControl('category', this.formBuilder.control({value: category ?? null, disabled: !this.isBaseLanguage}));
            if (this.currentMultiCategories?.length <= 0) {
                this.contentEditorFormGroup.get('category').disable();
            }
        }

        // on désactive l'ancien système de détection de changements
        if (this.editorValueChangesSubscription) this.editorValueChangesSubscription.unsubscribe();

        // on active le système de détection de changements
        this.editorValueChangesSubscription = this.contentEditorFormGroup.valueChanges.subscribe(() => {
            this.changesSubject.next(true);
        });

        this.editorValueHintChangesDebounceTimeSubscription = this.contentEditorFormGroup.get('hint')?.valueChanges.pipe(debounceTime(500)).subscribe((event: any) => {
            if (this.noHistoryTemp) return;
            this.onValueChange('hint');
        });

        this.editorValueLocationChangesDebounceTimeSubscription = this.contentEditorFormGroup.get('location')?.valueChanges.pipe(debounceTime(500)).subscribe((event: any) => {
            if (this.noHistoryTemp) return;
            this.onValueChange('location');
        });
    }

    updateFormGroup(code: CodeHuntCodeUI = null) {
        if (!code) return;

        if (this.hasMultiCategories) {
            this.contentEditorFormGroup.reset({
                hint: code?.hint ?? null,
                location: code?.location ?? null,
                category: code?.category ?? null,
            }, { emitEvent: false });
        } else {
            this.contentEditorFormGroup.reset({
                hint: code?.hint ?? null,
                location: code?.location ?? null,
            }, { emitEvent: false });
        }

        this.resetInputSubject.next();
    }

    onValueChange(inputName: string) {
        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode?.code, inputName });
    }

    saveContentLocal() {
        if (!this.selectedCode || !this.selectedCode?.code) return;

        const idx = this.codesList.findIndex(c => c.code === this.selectedCode.code);
        if (idx >= 0 && this.codesList[idx]) {
            this.codesList[idx].hint = this.contentEditorFormGroup.get('hint').value;
            this.codesList[idx].location = this.contentEditorFormGroup.get('location').value;
            if (this.hasMultiCategories) {
                this.codesList[idx].category = this.contentEditorFormGroup.get('category').value;
            }
        }
    }

    noSave: boolean = false;

    async saveContent(isAutoSave: boolean = false) {
        this.isFormSubmitted = true;

        // sauvegarde des champs actifs sur la page actuelle
        this.saveContentLocal();

        // on check si on a une erreur sur le code où on se trouve, on ne lance pas la save
        if (this.selectedCode && this.selectedCode?.code) {
            const idx = this.codesList.findIndex(c => c.code === this.selectedCode.code);
            if (this.codesList[idx] && this.hasErrorCode(this.codesList[idx])) {
                return;
            }
        }

        // si c'est un autoSave, on emit pour que le parent puisse afficher l'animation d'autoSave en bas de page
        if (isAutoSave) {
            this.autoSaving.emit({ state: true });
        }

        // début de construction de la config finale à save
        const modularRoot = { ModularRoot: this.config?.modularConfig };

        // on applique la bonne configuration (avec language, title etc)
        if (this.languages?.length > 1 && this.modularConfigByLang[this.currentLanguage] && this.modularConfigByLang[this.currentLanguage]?.language === this.currentLanguage) {
            modularRoot.ModularRoot.configuration = this.modularConfigByLang[this.currentLanguage];
        } else {
            modularRoot.ModularRoot.configuration = this.config?.configuration;
        }


        // on récupère la confing de tout le codehunt
        const codehuntConfigFinal = this.codeHuntConfig;

        // build de l'array de codes au format DB
        const finalCodes = this.codesList?.map((code) => ({
            code: code?.code,
            hint: {
                content: code?.hint && code?.hint?.length <= 300 ? code?.hint : code?.hint?.slice(0, 300),
                location: code?.location && code?.location?.length <= 100 ? code?.location : code?.location?.slice(0, 100),
            },
            status: code?.status,
            subExpConfig: code?.subExpConfig && code?.subExpConfig?.length > 0 ? code?.subExpConfig?.map(q => ({
                SimpleQuiz: q
            })) : null,
            quizLength: code?.subExpConfig?.length ?? 0,
            category: code?.category,
            points: this.structureConfig?.codesPoints ?? 50
        })
        ) ?? [];

        codehuntConfigFinal.codes = finalCodes;

        // Set de la config finale du codeHunt au bon endroit dans le modularRoot
        if (modularRoot.ModularRoot?.content[modularRoot?.ModularRoot?.content?.length - 1]?.CodeHunt) {
            modularRoot.ModularRoot.content[modularRoot?.ModularRoot?.content?.length - 1].CodeHunt = codehuntConfigFinal;
        }


        try {
            if (this.noSave) console.log('save classic Codehunt', JSON.parse(JSON.stringify(modularRoot)));

            if (!this.noSave) await firstValueFrom(this.orderService.configureGenericExperience(this.orderId, modularRoot));

            if (this.saveSubject) {
                this.saveSubject.next({ ref: this, nextUrl: null });
            }

            // récupération de l'info s'il y a au moins une erreur
            let hasAtLeastOneError = false;
            this.codesList?.forEach((code) => {
                if (this.hasErrorCode(code)) {
                    hasAtLeastOneError = true;
                }
            });

            // si il n'y a pas au moins une erreur, on reset le state de changement
            if (!hasAtLeastOneError) {
                this.changesSubject.next(false);
            }

            if (isAutoSave) {
                // on emit direct les changements au cas où l'user viendrait à changer de page directement après ses modifs
                const object: any = { state: true, key: 'content', config: JSON.parse(JSON.stringify(finalCodes)), language: this.currentLanguage };
                this.autoSaving.emit(object);

                // si c'est un autoSave, on emit pour que le parent puisse arrêter l'animation d'autoSave en bas de page
                // avec un délai de 2s pour que l'animation ait le temps de se faire
                setTimeout(() => {
                    object.state = false;
                    this.autoSaving.emit(object);
                }, 2000);
            }
        } catch (error) {
            if (isAutoSave) {
                this.autoSaving.emit(null);
            }
            console.log('error while saving content of codehunt configurator', error);
        }
    }

    async saveContentByLang(codesList: any, lang: string) {
        // début de construction de la config finale à save
        if (!this.configByLang || !this.configByLang[lang]) return;

        const modularRoot: any = { ModularRoot: JSON.parse(JSON.stringify(this.config?.modularConfig)) };

        if (!this.modularConfigByLang || !this.modularConfigByLang[lang]) {
            console.log('pas de modular config pour la langue', lang);
            return;
        }

        // on set la bonne configuration pour la langue
        modularRoot.ModularRoot.configuration = JSON.parse(JSON.stringify(this.modularConfigByLang[lang]));

        // on récupère la confing de tout le codehunt
        const codehuntConfigFinal = JSON.parse(JSON.stringify(this.configByLang[lang]));

        // build de l'array de codes au format DB
        const finalCodes = codesList?.map((code) => ({
            code: code?.code,
            hint: {
                content: code?.hint?.content ? (code?.hint?.content && code?.hint?.content?.length <= 300 ? code?.hint?.content : code?.hint?.content?.slice(0, 300)) : null,
                location: code?.hint?.location ? (code?.hint?.location && code?.hint?.location?.length <= 100 ? code?.hint?.location : code?.hint?.location?.slice(0, 100)) : null,
            },
            status: code?.status,
            subExpConfig: code?.subExpConfig && code?.subExpConfig?.length > 0 ? code?.subExpConfig : null,
            quizLength: code?.subExpConfig?.length ?? 0,
            category: code?.category ?? null,
            points: this.structureConfig?.codesPoints ?? 50
        })
        ) ?? [];

        codehuntConfigFinal.codes = finalCodes;

        // Set de la config finale du codeHunt au bon endroit dans le modularRoot
        if (modularRoot.ModularRoot?.content[modularRoot?.ModularRoot?.content?.length - 1]?.CodeHunt) {
            modularRoot.ModularRoot.content[modularRoot?.ModularRoot?.content?.length - 1].CodeHunt = codehuntConfigFinal;
        }
        try {
            if (this.noSave) console.log('save lang', lang, JSON.parse(JSON.stringify(modularRoot)));
            if (!this.noSave) await firstValueFrom(this.orderService.configureGenericExperience(this.orderId, modularRoot));

            const object: any = { state: true, key: 'content', config: JSON.parse(JSON.stringify(finalCodes)), language: lang };
            this.autoSaving.emit(object);
        } catch (error) {
            console.log('error while saving content of codehunt configurator', error);
        }
    }

    async onAddEditSubExpQuestionClick(questionIdx: number = null) {
        let config = null;
        if (questionIdx === null) {
            config = [new SimpleQuiz()];
            config[0].index = this.selectedCode?.subExpConfig?.length ?? 0;
        } else {
            config = JSON.parse(JSON.stringify(this.selectedCode?.subExpConfig));
        }

        const props: any = {
            config,
            isAdd: questionIdx === null,
            idx: questionIdx ?? 0,
            selectedLanguage: this.currentLanguage,
        };

        const modal = await this.modalController.create({
            component: ModalSubExpConfiguratorComponent,
            componentProps: props,
            cssClass: 'modal-sub-exp-configurator',
            backdropDismiss: false
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.role === 'confirm') {
                this.saveContentLocal();
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);

                if (dataReturned.data?.config?.length > 0) {

                    if (questionIdx === null) {
                        if (!this.selectedCode.subExpConfig) {
                            this.selectedCode.subExpConfig = [];
                        }

                        if (dataReturned?.data?.config?.length > 1) {
                            dataReturned.data.config.forEach((newConfig, idx) => {
                                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);
                                this.selectedCode.subExpConfig.push(newConfig);
                            });
                        } else {
                            this.selectedCode.subExpConfig = [...this.selectedCode.subExpConfig, ...dataReturned.data.config];
                        }
                    } else {
                        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);

                        this.selectedCode.subExpConfig = [...dataReturned.data.config];
                    }

                    this.saveContent(true);
                }
            }
        });

        return await modal.present();
    }

    onMoveSubExpQuestionTopClick(questionIdx: number) {
        if (questionIdx <= 0 || questionIdx >= this.selectedCode?.subExpConfig?.length || this.animatingQuestions) return;

        const configAtStart = JSON.parse(JSON.stringify(this.codesList));

        this.animatingQuestions = true;

        const elements: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('configurator-quiz-item') as any;

        // get idx element height;
        const elementsToMoveTop = elements[questionIdx];
        const elementToMoveTopHeight = elementsToMoveTop.clientHeight;

        // get idx-1 element height;
        const elementsToMoveBottom = elements[questionIdx - 1];
        const elementToMoveBottomHeight = elementsToMoveBottom.clientHeight;

        elementsToMoveTop.style.transition = `transform 0.4s ease-in-out`;
        elementsToMoveTop.style.zIndex = '10';
        elementsToMoveTop.style.transform = `translateY(-${elementToMoveBottomHeight}px)`;

        elementsToMoveBottom.style.transition = `transform 0.4s ease-in-out`;
        elementsToMoveBottom.style.transform = `translateY(${elementToMoveTopHeight}px)`;


        setTimeout(() => {
            elementsToMoveTop.style.transform = `translateY(0px)`;
            elementsToMoveTop.style.transition = `none`;
            elementsToMoveBottom.style.transform = `translateY(0px)`;
            elementsToMoveBottom.style.transition = `none`;

            const tmp = this.selectedCode?.subExpConfig[questionIdx];
            this.selectedCode.subExpConfig[questionIdx] = this.selectedCode?.subExpConfig[questionIdx - 1];
            this.selectedCode.subExpConfig[questionIdx - 1] = tmp;

            this.selectedCode.subExpConfig?.forEach((q, idx) => {
                if (q?.SimpleQuiz) {
                    q.SimpleQuiz.index = idx;
                } else {
                    q.index = idx;
                }
            });

            this.registerNewHistoryEvent(configAtStart, { code: this.selectedCode.code });

            if (this.languages?.length > 1) {
                const selectedCodeIdx = this.codesList?.findIndex(code => code?.code === this.selectedCode?.code);

                if (selectedCodeIdx >= 0) {
                    this.languagesWithoutBase.forEach(lang => {
                        if (!this.configByLang[lang] || !this.configByLang[lang]?.codes || this.configByLang[lang].codes?.length <= selectedCodeIdx) return;
                        if (!this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig || this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig?.length <= questionIdx) return;

                        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), {key: 'language-update', lang}, true);

                        const tmpLang = this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig[questionIdx];
                        this.configByLang[lang].codes[selectedCodeIdx].subExpConfig[questionIdx] = this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig[questionIdx - 1];
                        this.configByLang[lang].codes[selectedCodeIdx].subExpConfig[questionIdx - 1] = tmpLang;

                        this.configByLang[lang].codes[selectedCodeIdx].subExpConfig?.forEach((q, idx) => {
                            if (q?.SimpleQuiz) {
                                q.SimpleQuiz.index = idx;
                            } else {
                                q.index = idx;
                            }
                        });

                        this.saveContentByLang(this.configByLang[lang].codes, lang);
                    });
                }
            }

            elementsToMoveTop.style.zIndex = null;
            this.animatingQuestions = false;
        }, 400);
    }

    onMoveSubExpQuestionBottomClick(questionIdx: number) {
        if (this.selectedCode?.subExpConfig?.length <= 0 || questionIdx >= this.selectedCode?.subExpConfig?.length - 1 || this.animatingQuestions) return;

        const configAtStart = JSON.parse(JSON.stringify(this.codesList));

        this.animatingQuestions = true;

        const elements: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('configurator-quiz-item') as any;

        // get idx element height;
        const elementsToMoveBottom = elements[questionIdx];
        const elementToMoveBottomHeight = elementsToMoveBottom.clientHeight;

        // get idx+1 element height;
        const elementsToMoveTop = elements[questionIdx + 1];
        const elementToMoveTopHeight = elementsToMoveTop.clientHeight;

        elementsToMoveTop.style.transition = `transform 0.4s ease-in-out`;
        elementsToMoveTop.style.transform = `translateY(-${elementToMoveBottomHeight}px)`;

        elementsToMoveBottom.style.transition = `transform 0.4s ease-in-out`;
        elementsToMoveBottom.style.transform = `translateY(${elementToMoveTopHeight}px)`;
        elementsToMoveBottom.style.zIndex = '10';

        setTimeout(() => {
            elementsToMoveTop.style.transform = `translateY(0px)`;
            elementsToMoveTop.style.transition = `none`;
            elementsToMoveBottom.style.transform = `translateY(0px)`;
            elementsToMoveBottom.style.transition = `none`;

            const tmp = this.selectedCode?.subExpConfig[questionIdx];
            this.selectedCode.subExpConfig[questionIdx] = this.selectedCode?.subExpConfig[questionIdx + 1];
            this.selectedCode.subExpConfig[questionIdx + 1] = tmp;

            this.selectedCode.subExpConfig?.forEach((q, idx) => {
                if (q?.SimpleQuiz) {
                    q.SimpleQuiz.index = idx;
                } else {
                    q.index = idx;
                }
            });

            this.registerNewHistoryEvent(configAtStart, { code: this.selectedCode.code });

            if (this.languages?.length > 1) {
                const selectedCodeIdx = this.codesList?.findIndex(code => code?.code === this.selectedCode?.code);

                if (selectedCodeIdx >= 0) {
                    this.languagesWithoutBase.forEach(lang => {
                        if (!this.configByLang[lang] || !this.configByLang[lang]?.codes || this.configByLang[lang].codes?.length <= selectedCodeIdx) return;
                        if (!this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig || this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig?.length <= questionIdx) return;

                        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), {key: 'language-update', lang}, true);

                        const tmpLang = this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig[questionIdx];
                        this.configByLang[lang].codes[selectedCodeIdx].subExpConfig[questionIdx] = this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig[questionIdx + 1];
                        this.configByLang[lang].codes[selectedCodeIdx].subExpConfig[questionIdx + 1] = tmpLang;

                        this.configByLang[lang].codes[selectedCodeIdx].subExpConfig?.forEach((q, idx) => {
                            if (q?.SimpleQuiz) {
                                q.SimpleQuiz.index = idx;
                            } else {
                                q.index = idx;
                            }
                        });

                        this.saveContentByLang(this.configByLang[lang].codes, lang);
                    });
                }
            }

            elementsToMoveBottom.style.zIndex = null;
            this.animatingQuestions = false;
        }, 400);
    }

    async onDeleteSubExpQuestionClick(questionIdx: number) {
        if (!this.selectedCode || !this.selectedCode.subExpConfig || questionIdx < 0 || questionIdx >= this.selectedCode.subExpConfig.length) return;

        const question = this.selectedCode.subExpConfig[questionIdx].question;

        if (question?.length > 100) {
            // splice question to 100 char max and add '...' at the end
            this.selectedCode.subExpConfig[questionIdx].question = question.slice(0, 100) + '...';
        }

        const props: any = {
            paramTitle: 'Voulez-vous vraiment supprimer cette question ?',
            paramDescription: '\'' + this.selectedCode.subExpConfig[questionIdx].question + '\'',
        };

        const modal = await this.modalController.create({
            component: ModalConfirmComponent,
            componentProps: props,
            cssClass: 'modal-confirm-custom',
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.data === 'confirm') {
                this.changesSubject.next(true);
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code, lang: this.currentLanguage }, true);

                // delete question from list
                this.selectedCode.subExpConfig.splice(questionIdx, 1);

                if (this.selectedCode?.subExpConfig?.length > 0) {
                    this.selectedCode.subExpConfig.forEach((q, idx) => {
                        q.index = idx;
                    });
                }

                if (this.languages?.length > 1) {
                    const selectedCodeIdx = this.codesList?.findIndex(code => code?.code === this.selectedCode?.code);

                    if (selectedCodeIdx >= 0) {
                        this.languagesWithoutBase.forEach(lang => {
                            if (!this.configByLang[lang] || !this.configByLang[lang]?.codes || this.configByLang[lang].codes?.length <= selectedCodeIdx) return;
                            if (!this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig || this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig?.length <= questionIdx) return;

                            this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), {key: 'language-update', lang}, true);

                            this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig.splice(questionIdx, 1);

                            if (this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig?.length > 0) {
                                this.configByLang[lang].codes[selectedCodeIdx]?.subExpConfig.forEach((q, idx) => {
                                    q.SimpleQuiz.index = idx;
                                });
                            }

                            this.saveContentByLang(this.configByLang[lang].codes, lang);
                        });
                    }
                }

                this.saveContent(true);
            }
        });
        return await modal.present();
    }



    waitingTiming = 400;

    onUndoClick() {
        if (this.configHistoryStack.length <= 0) return;

        // on active le mode changement d'historique pour ne pas trigger les onValueChanges des input
        this.noHistoryTemp = true;

        // on garde la config actuelle
        const currentConfig = JSON.parse(JSON.stringify(this.codesList));

        // on récupère la dernière config de l'historique
        const historyConfig = this.configHistoryStack.pop();

        // s'il y a des métadonnées, on les garde
        const metaData: any = { ...historyConfig?.metadata };
        if (historyConfig.metadata?.code) metaData.code = historyConfig.metadata.code;

        const metaDataRedo = JSON.parse(JSON.stringify(metaData));
        if (metaData?.key === 'multi-categories') {
            metaDataRedo.categories = JSON.parse(JSON.stringify(this.codeHuntConfig.multiCategories));
        }
        // console.log('metaDataRedo', JSON.parse(JSON.stringify(metaDataRedo)));
        // console.log('currentMultiCategories', JSON.parse(JSON.stringify(this.currentMultiCategories)));

        if (metaData?.key === 'language-update') {
            this.redoHistoryStack.push({ config: this.configByLang[metaData?.lang]?.codes, metadata: metaDataRedo });

            if (metaData?.categories) {
                metaDataRedo.categories = JSON.parse(JSON.stringify((this.configByLang[metaData?.lang]?.multiCategories)));
                this.configByLang[metaData.lang].multiCategories = metaData?.categories;
            }

            const object: any = { state: true, key: 'content', config: JSON.parse(JSON.stringify(historyConfig?.config)), language: metaData?.lang };
            this.autoSaving.emit(object);

            this.saveContentByLang(historyConfig?.config, metaData?.lang);

            this.onUndoClick();
            return;
        }

        if (this.redoHistoryStack?.length > 0 && this.redoHistoryStack[this.redoHistoryStack?.length - 1]?.metadata?.key === 'language-update') {
            let idx = 0;

            // trouver l'idx juste avant les éléments language-update, il peut y avoir plusieurs language-update à la suite
            for (let i = this.redoHistoryStack.length - 1; i >= 0; i--) {
                if (this.redoHistoryStack[i]?.metadata?.key !== 'language-update') {
                    idx = i;
                    break;
                }
            }

            // on glisse la config actuelle à l'indes trouvé précédemment
            this.redoHistoryStack.splice(idx, 0, { config: currentConfig, metadata: metaDataRedo });

        } else {
            // on ajoute la config actuelle à l'historique de redo
            this.redoHistoryStack.push({ config: currentConfig, metadata: metaDataRedo });
        }

        this.undoStatus.emit(this.configHistoryStack.length > 0);
        this.redoStatus.emit(this.redoHistoryStack.length > 0);


        let timeout = 0;


        if (metaData?.key === 'add-element') {
            if (this.codesList?.length <= 1) {
                this.editorMode = false;
                this.afterEditorMode = false;
                this.editorModeChanged.next({ state: false, initiator: 'child' });
            } else {
                // go to the last element of the list
                this.onEditClick(this.codesList[this.codesList.length - 2], null, true);
                timeout = 200;
            }
        }

        // si on a des métadonnées de code, on change le code sélectionné
        if (historyConfig?.metadata?.code && historyConfig.metadata?.code !== this.selectedCode?.code) {
            this.onEditClick(this.codesList.find(c => c.code === historyConfig.metadata?.code));
            timeout = this.waitingTiming;
        }


        // on attends soit 0 soit 400ms pour changer la config (durée de l'animation de navigation entre les codes)
        setTimeout(() => {
            // on change la config actuelle par la config de l'historique
            this.codesList = JSON.parse(JSON.stringify(historyConfig.config));

            // on update le code sélectionné
            this.selectedCode = this.codesList.find(c => c.code === this.selectedCode?.code);

            if (metaData?.key === 'multi-categories' && historyConfig.metadata?.categories?.length >= 0) {
                this.codeHuntConfig.multiCategories = historyConfig.metadata.categories;
                this.codeHuntConfig.codes = JSON.parse(JSON.stringify(this.codesList));
            }

            // si on a des métadonnées de code, on initialise le formGroup
            if (historyConfig?.metadata?.code) {
                const code = this.codesList.find(c => c.code === historyConfig.metadata?.code);

                if (this.selectedCode.code === code.code) {
                    this.initFormGroup(code);
                }
            }

            this.saveContent(true);

            // si on a des métadonnées d'input, on focus sur l'input
            if (historyConfig?.metadata?.inputName) {
                if (historyConfig.metadata.inputName === 'hint') {
                    const inputElement = document.getElementsByClassName('mInput_textarea_container')[0];
                    if (inputElement && inputElement?.children?.length > 0) {
                        (inputElement.children[0] as any).focus();
                    }
                } else {
                    const inputElement = document.getElementById('input-' + historyConfig.metadata.inputName);
                    inputElement.focus();
                }
            }
            // si on a pas de métadonnées d'input, on enlève le focus pour éviter de focus sur un input
            else {
                const inputElementHint = document.getElementsByClassName('mInput_textarea_container')[0];
                if (inputElementHint && inputElementHint?.children?.length > 0) {
                    (inputElementHint.children[0] as any).blur();
                }

                const inputElementLocation = document.getElementById('input-location');
                if (inputElementLocation) inputElementLocation.blur();
            }

            // on enlève le mode changement d'historique après 600ms
            setTimeout(() => {
                this.noHistoryTemp = false;
            }, 600);
        }, timeout);
    }

    onRedoClick() {
        if (this.redoHistoryStack.length <= 0) return;

        // on active le mode changement d'historique pour ne pas trigger les onValueChanges des input
        this.noHistoryTemp = true;

        // on garde la config actuelle
        const currentConfig = JSON.parse(JSON.stringify(this.codesList));

        // on récupère la dernière config de l'historique
        const historyConfig = this.redoHistoryStack.pop();

        let timeout = 0;

        // si on a des métadonnées de code, on change le code sélectionné
        if (historyConfig?.metadata?.key !== 'delete-code' && historyConfig?.metadata?.code && historyConfig.metadata?.code !== this.selectedCode.code) {
            this.onEditClick(this.codesList.find(c => c.code === historyConfig.metadata?.code));
            timeout = this.waitingTiming;
        }

        const metaDataUndo = JSON.parse(JSON.stringify(historyConfig.metadata));
        if (metaDataUndo?.key === 'multi-categories') {
            metaDataUndo.categories = JSON.parse(JSON.stringify(this.codeHuntConfig.multiCategories));
        }

        if (historyConfig?.metadata?.key === 'language-update') {
            this.configHistoryStack.push({ config: this.configByLang[metaDataUndo?.lang]?.codes, metadata: metaDataUndo });

            if (historyConfig?.metadata?.categories) {
                metaDataUndo.categories = JSON.parse(JSON.stringify((this.configByLang[historyConfig.metadata?.lang]?.multiCategories)));
                this.configByLang[historyConfig.metadata.lang].multiCategories = historyConfig.metadata?.categories;
            }

            const object: any = { state: true, key: 'content', config: JSON.parse(JSON.stringify(historyConfig?.config)), language: historyConfig?.metadata?.lang };
            this.autoSaving.emit(object);

            this.saveContentByLang(historyConfig?.config, historyConfig?.metadata?.lang);

            this.onRedoClick();
            return;
        }

        if (this.configHistoryStack?.length > 0 && this.configHistoryStack[this.configHistoryStack?.length - 1]?.metadata?.key === 'language-update') {
            let idx = 0;

            // trouver l'idx juste avant les éléments language-update
            for (let i = this.configHistoryStack.length - 1; i >= 0; i--) {
                if (this.configHistoryStack[i]?.metadata?.key !== 'language-update') {
                    idx = i;
                    break;
                }
            }

            // on glisse la config actuelle à l'indes trouvé précédemment
            this.configHistoryStack.splice(idx, 0, { config: currentConfig, metadata: metaDataUndo });

        } else {
            // on ajoute la config actuelle à l'historique de redo
            this.configHistoryStack.push({ config: currentConfig, metadata: metaDataUndo });
        }

        this.undoStatus.emit(this.configHistoryStack.length > 0);
        this.redoStatus.emit(this.redoHistoryStack.length > 0);

        // on attends soit 0 soit waitingTime pour changer la config (durée de l'animation de navigation entre les codes)
        setTimeout(() => {
            // on change la config actuelle par la config de l'historique
            this.codesList = historyConfig.config;
            // on update le code sélectionné
            this.selectedCode = this.codesList.find(c => c.code === this.selectedCode?.code);

            const code = this.codesList.find(c => c.code === historyConfig.metadata?.code);

            if (historyConfig?.metadata?.key === 'multi-categories' && historyConfig.metadata?.categories?.length > 0) {
                this.codeHuntConfig.multiCategories = historyConfig.metadata.categories;
                this.codeHuntConfig.codes = JSON.parse(JSON.stringify(this.codesList));
            }

            // si on a des métadonnées de code, on initialise le formGroup
            if (historyConfig.metadata?.code) {
                if (this.selectedCode.code === code.code) {
                    this.initFormGroup(code);
                }
            }

            this.saveContent(true);

            // si on a des métadonnées d'input, on focus sur l'input
            if (historyConfig?.metadata?.inputName) {
                if (historyConfig.metadata.inputName === 'hint') {
                    const inputElement = document.getElementsByClassName('mInput_textarea_container')[0];
                    if (inputElement && inputElement?.children?.length > 0) {
                        (inputElement.children[0] as any).focus();
                    }
                } else {
                    const inputElement = document.getElementById('input-' + historyConfig.metadata.inputName);
                    inputElement.focus();
                }
            }
            // si on a pas de métadonnées d'input, on enlève le focus pour éviter de focus sur un input
            else {
                // remove focus, to avoid focus on input
                const inputElementHint = document.getElementsByClassName('mInput_textarea_container')[0];
                if (inputElementHint && inputElementHint?.children?.length > 0) {
                    (inputElementHint.children[0] as any).blur();
                }

                const inputElementLocation = document.getElementById('input-location');
                if (inputElementLocation) inputElementLocation.blur();
            }

            // on enlève le mode changement d'historique après 600ms
            setTimeout(() => {
                this.noHistoryTemp = false;
            }, 600);
        }, timeout);
    }

    hasErrorCode(code: CodeHuntCodeUI) {
        if (!code) return false;

        if (code?.status === BadgeStatus.INACTIVE) return false;

        return (code?.hint && code?.hint?.length > 300) || (code?.location && code?.location?.length > 100);
    }

    async onDeleteCodeClick() {
        const props: any = {
            paramTitle: 'Voulez-vous vraiment supprimer ce QR Code ?',
            paramDescription: '\"' + this.selectedCode.code + '\"',
        };

        const modal = await this.modalController.create({
            component: ModalConfirmComponent,
            componentProps: props,
            cssClass: 'modal-confirm-custom',
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.data === 'confirm') {
                // delete code from list
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code, key: 'delete-code' }, true);

                const selectedCodeIdx = this.codesList?.findIndex(code => code?.code === this.selectedCode?.code);

                this.codesList = this.codesList.filter(c => c.code !== this.selectedCode.code);

                if (this.languages?.length > 1) {

                    if (selectedCodeIdx >= 0) {
                        this.languagesWithoutBase.forEach(lang => {
                            if (!this.configByLang[lang] || !this.configByLang[lang]?.codes || this.configByLang[lang].codes?.length <= selectedCodeIdx) return;

                            this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), {key: 'language-update', lang}, true);

                            this.configByLang[lang].codes.splice(selectedCodeIdx, 1);

                            this.saveContentByLang(this.configByLang[lang].codes, lang);
                        });
                    }
                }

                this.saveContent(true);

                // select first code
                if (this.codesList.length > 0) {
                    this.onEditClick(this.codesList[0], null, true);
                } else {
                    this.selectedCode = null;
                    this.editorMode = false;
                    this.afterEditorMode = false;
                    this.editorModeChanged.next({ state: false, initiator: 'child' });
                }
            }
        });
        return await modal.present();
    }

    async addCategory(isEdit: boolean = false) {
        const props: any = {
            categoryList: this.currentMultiCategories,
            languages: this.languages,
        };

        let cateIdx = -1;
        if (isEdit) {
            cateIdx = this.currentMultiCategories.findIndex(c => c === this.contentEditorFormGroup.get('category').value);

            props.category = {};

            props.category[this.baseLanguage] = this.contentEditorFormGroup.get('category').value;

            if (this.languages?.length > 1) {
                this.languagesWithoutBase.forEach(lang => {
                    if (!this.configByLang[lang] || !this.configByLang[lang]?.multiCategories || this.configByLang[lang]?.multiCategories?.length <= cateIdx) return;

                    props.category[lang] = this.configByLang[lang]?.multiCategories[cateIdx];
                });
            }
        }

        const modal = await this.modalController.create({
            component: ModalAddCategoryComponent,
            componentProps: props,
            backdropDismiss: false,
            cssClass: 'modal-add-category',
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned?.data && dataReturned?.data[this.baseLanguage]) {
                // on ajoute l'event dans l'historique
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { key: 'multi-categories', code: this.selectedCode?.code, categories: JSON.parse(JSON.stringify(this.currentMultiCategories)) }, true);

                if (!isEdit) {
                    // on ajoute la nouvelle catégorie
                    this.currentMultiCategories.push(dataReturned.data[this.baseLanguage]);
                } else {
                    // on update la catégorie de codeHuntConfig.multiCategories
                    this.codeHuntConfig.multiCategories = this.currentMultiCategories.map(c => c === this.contentEditorFormGroup.get('category').value ? dataReturned.data[this.baseLanguage] : c);
                }

                if (this.currentMultiCategories.length > 0) {
                    this.contentEditorFormGroup.get('category').enable();
                }

                // on update le formGroup
                this.contentEditorFormGroup.get('category').setValue(dataReturned.data[this.baseLanguage]);
                this.selectedCode.category = dataReturned.data[this.baseLanguage];

                this.codeHuntConfig.codes = JSON.parse(JSON.stringify(this.codesList));

                // on save le contenu
                this.saveContent(true);

                if (this.languages?.length > 1) {
                    this.languagesWithoutBase.forEach(lang => {
                        if (!this.configByLang[lang] || !this.configByLang[lang]?.codes|| !dataReturned || !dataReturned?.data || !dataReturned.data[lang]) return;

                        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), {key: 'language-update', lang, categories: JSON.parse(JSON.stringify(this.configByLang[lang].multiCategories ?? []))}, true);

                        this.configByLang[lang].codes.forEach((code, idx) => {
                            if (code?.code === this.selectedCode?.code) {
                                code.category = dataReturned.data[lang];
                            }
                        });

                        if (!isEdit) {
                            if (!this.configByLang[lang].multiCategories) {
                                this.configByLang[lang].multiCategories = [];
                            }
                            this.configByLang[lang].multiCategories.push(dataReturned.data[lang]);
                        } else {
                            //check length of multiCategories by lang
                            if (this.configByLang[lang].multiCategories?.length <= cateIdx) return;

                            this.configByLang[lang].multiCategories[cateIdx] = dataReturned.data[lang];
                        }

                        this.saveContentByLang(this.configByLang[lang].codes, lang);
                    });
                }
            }
        });

        return await modal.present();
    }

    onCategoryChange() {
        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);

        // find selected code category idx
        const categoryIdx = this.currentMultiCategories.findIndex(c => c === this.contentEditorFormGroup.get('category').value);

        if (this.languages?.length > 1) {
            this.languagesWithoutBase.forEach(lang => {
                if (!this.configByLang[lang] || !this.configByLang[lang]?.multiCategories || this.configByLang[lang]?.multiCategories?.length <= categoryIdx) return;

                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), {key: 'language-update', lang}, true);

                this.configByLang[lang].codes.forEach((code, idx) => {
                    if (code?.code === this.selectedCode?.code) {
                        code.category = this.configByLang[lang].multiCategories[categoryIdx];
                    }
                });

                this.saveContentByLang(this.configByLang[lang].codes, lang);
            });
        }

        this.saveContent(true);
    }

    editCategory() {
        this.addCategory(true);
    }

    async removeDefinitelyCategory() {
        const props: any = {
            paramTitle: 'Voulez-vous vraiment supprimer cette catégorie ?',
            paramDescription: '"' + this.contentEditorFormGroup.get('category').value + '"',
        };

        const modal = await this.modalController.create({
            component: ModalConfirmComponent,
            componentProps: props,
            cssClass: 'modal-confirm-custom',
        });

        modal.onWillDismiss().then(async (dataReturned: any) => {
            if (dataReturned?.data === 'confirm') {
                this.saveContentLocal();

                // on ajoute l'event dans l'historique
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { key: 'multi-categories', code: this.selectedCode?.code, categories: JSON.parse(JSON.stringify(this.currentMultiCategories)) }, true);

                // find selected code category idx
                const categoryIdx = this.currentMultiCategories.findIndex(c => c === this.contentEditorFormGroup.get('category').value);

                if (categoryIdx < 0) return;

                if (this.languages?.length > 1) {
                    this.languagesWithoutBase.forEach(lang => {
                        if (!this.configByLang[lang] || !this.configByLang[lang]?.multiCategories || this.configByLang[lang]?.multiCategories?.length <= categoryIdx) return;

                        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.configByLang[lang].codes)), {key: 'language-update', lang, categories: JSON.parse(JSON.stringify(this.configByLang[lang].multiCategories ?? []))}, true);

                        // on enlève la category de tous les codes qui ont celle qu'on veut supprimer
                        this.configByLang[lang].codes.forEach((code, idx) => {
                            if (code?.category === this.configByLang[lang].multiCategories[categoryIdx]) {
                                code.category = null;
                            }
                        });

                        // on supprime la catégorie de configByLang.multiCategories en utilisant cateIdx
                        this.configByLang[lang].multiCategories?.splice(categoryIdx, 1);

                        this.saveContentByLang(this.configByLang[lang].codes, lang);
                    });
                }

                // on supprime la catégorie de codeHuntConfig.multiCategories
                this.codeHuntConfig.multiCategories?.splice(categoryIdx, 1);

                if (this.currentMultiCategories.length === 0) {
                    this.contentEditorFormGroup.get('category').disable();
                } else {
                    this.contentEditorFormGroup.get('category').enable();
                }

                // on update le formGroup
                this.contentEditorFormGroup.get('category').setValue(null);

                // on save le contenu
                this.saveContent(true);
            }
        });
        return await modal.present();
    }

    getQuizLength(code: CodeHuntCodeUI) {
        return code?.subExpConfig?.length ?? 0;
    }

    toggleEditorMode() {
        let timeout = 0;
        if (!this.editorMode) {
            timeout = 500;
        }

        if (timeout) {
            setTimeout(() => {
                this.afterEditorMode = !this.afterEditorMode;
            }, timeout);
        } else {
            this.afterEditorMode = !this.afterEditorMode;
        }

        this.editorMode = !this.editorMode;
    }

    getQuizDiff(code: CodeHuntCodeUI): any {
        const baseCode = this.baseCodeHuntConfig?.codes?.find(c => c.code === code.code);
        if (!baseCode || !baseCode?.subExpConfig || baseCode?.subExpConfig?.length <= 0) return '';

        let currentLength = 0;
        if (code?.subExpConfig && code?.subExpConfig?.length > 0) currentLength = code?.subExpConfig?.filter(subExp => subExp?.question !== '' && subExp?.question?.length > 0)?.length;

        const toTranslate = !code?.subExpConfig || code?.subExpConfig?.some(subExp => subExp?.toTranslate === undefined || subExp?.toTranslate === true);

        if (currentLength === baseCode?.subExpConfig?.length) {
            return {toTranslate, content: code?.subExpConfig?.length + ' question' + (code?.subExpConfig?.length > 1 ? 's' : '')};
        } else {
            return {toTranslate: true, content: (baseCode?.subExpConfig?.length - currentLength) + ' question' + ((baseCode?.subExpConfig?.length - currentLength) > 1 ? 's' : '') + ' à traduire'};
        }
    }

    getBaseCode(code: CodeHuntCodeUI): CodeHuntCode {
        const newCode = this.baseCodeHuntConfig?.codes?.find(c => c?.code === code?.code);

        if (!newCode) return null;

        return newCode;
    }

    getSubExpConfigForCode(code: CodeHuntCodeUI) {
        if (this.isBaseLanguage) return code?.subExpConfig;

        const baseCode = this.getBaseCode(code);
        if (!baseCode) return null;

        const finalSubExpConfig = [];

        for (let i = 0; i < baseCode?.subExpConfig?.length; i++) {
            let subExpItem = null;

            if (code?.subExpConfig && code?.subExpConfig?.length > i && code.subExpConfig[i] !== null
                && code.subExpConfig[i]?.question !== '' && code.subExpConfig[i]?.question?.length > 0 && code.subExpConfig[i]?.toTranslate === false)
            {
                subExpItem = code?.subExpConfig[i];
                if (!subExpItem) continue;
                subExpItem.toTranslate = false;
            } else {
                subExpItem = baseCode?.subExpConfig[i]?.SimpleQuiz;
                if (!subExpItem) continue;
                subExpItem.toTranslate = true;
            }


            if (subExpItem) finalSubExpConfig.push(subExpItem);
        }

        return finalSubExpConfig;
    }

    async onTranslateSubExpClick(questionIdx: number) {
        if (questionIdx === null) return;

        let config = JSON.parse(JSON.stringify(this.selectedCode.subExpConfig));
        const baseConfig = this.getBaseCode(this.selectedCode)?.subExpConfig;

        // si on a pas de baseConfig ou que la baseConfig est vide, on ne fait rien
        if (!baseConfig || baseConfig?.length <= 0) return;

        // si on a pas de config, on initialise un array vide
        if (!config) config = [];

        // si la config est plus petite que la baseConfig, on ajoute des questions vides
        if (config?.length < baseConfig?.length) {
            const diff = baseConfig?.length - config?.length;
            for (let i = 0; i < diff; i++) {
                config.push(new SimpleQuiz());
                config[config.length - 1].index = config.length - 1;
            }
        }

        if (!config || !baseConfig || baseConfig?.length <= questionIdx || config?.length <= questionIdx) return;

        const props: any = {
            config,
            baseConfig,
            isTranslate: true,
            idx: questionIdx ?? 0,
            selectedLanguage: this.currentLanguage,
        };

        const modal = await this.modalController.create({
            component: ModalSubExpConfiguratorComponent,
            componentProps: props,
            cssClass: 'modal-sub-exp-configurator',
            backdropDismiss: false
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.role === 'confirm') {
                this.saveContentLocal();
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);

                if (dataReturned.data?.config?.length > 0) {
                    if (!this.selectedCode.subExpConfig) {
                        this.selectedCode.subExpConfig = [];
                    }
                    for (let i = 0; i < baseConfig.length; i++) {
                        if (this.selectedCode?.subExpConfig?.length <= i) {
                            this.selectedCode.subExpConfig.push(new SimpleQuiz());
                            this.selectedCode.subExpConfig[this.selectedCode.subExpConfig?.length - 1].toTranslate = true;
                            this.selectedCode.subExpConfig[this.selectedCode.subExpConfig?.length - 1].index = i;
                        }

                        if (i === questionIdx) {
                            this.selectedCode.subExpConfig[i] = dataReturned.data.config[0];
                            this.selectedCode.subExpConfig[i].toTranslate = false;
                        }

                        this.selectedCode.subExpConfig[i].index = i;
                    }

                    this.saveContent(true);
                }
            }
        });

        return await modal.present();
    }
}
