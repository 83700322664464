<div class="url-socials-tooltip-container">
    <div class="icon-close" (click)="closeModal()" style="cursor: pointer;">
        <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
    </div>

    <div *ngIf="socialElementKey === socialElementKeyEnum.GOOGLE" class="google-container">
        <div class="title-social">
            Pour Google Avis
        </div>

        <div class="content-social">
            <ul>
                <li>
                    Pour récupérer le lien, vous devez être connecté à un compte gestionnaire de la page.
                </li>
    
                <li>
                    Accédez à votre <a href="https://www.google.com/intl/fr_fr/business/" target="_blank">fiche d'établissement</a> et connectez-vous.
                </li>
    
                <li>
                    Pour trouver le lien de votre avis, cliquez sur Lire Avis puis Obtenir plus d'avis.
                </li>
            </ul>
            
            Pour plus d’informations, nous vous invitons à nous contacter ou à consulter <a href="https://support.google.com/business/answer/3474122?hl=fr" target="_blank">l’aide Google</a>.
        </div>
    </div>


    <div *ngIf="socialElementKey === socialElementKeyEnum.INSTAGRAM" class="instagram-container">
        
        <div class="title-social">
            Pour Instagram
        </div>

        <div class="content-social">
            Fournissez simplement le nom de la page concernée.<br><br>
            Dans l’exemple ci-dessous, il faudrait remplir avec <b>les3fontaines</b>.
        </div>
        
        <div class="instagram-example">
            <img src="https://cdn.mocli.fr/images/example-instagram-socials.png" alt="">
        </div>

    </div>

    <div *ngIf="socialElementKey === 'preview'" class="preview-container">
        <div class="title-social">
            Aperçu
        </div>

        <div class="content-social">
            Voici un aperçu de l’encart affiché sur une page spécifique “Points bonus”
        </div>
        
        <div class="preview-list">

            <div class="preview-image">
                <img src="https://cdn.mocli.fr/images/preview-social-instagram.png" alt="">
            </div>
            
            <div class="preview-image">
                <img src="https://cdn.mocli.fr/images/preview-social-google.png" alt="">
            </div>
            
        </div>
    </div>
    
</div>