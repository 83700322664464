/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { HeaderBarService } from 'src/app/services/HeaderBarService';
import { BadgeStatus } from '../shared/status-badge/status-badge.component';
import { HistoryContentEditor } from '../configure/shared-exp-configurator/code-hunt-configurator/codehunt-content-editor/codehunt-content-editor.component';
import { BehaviorSubject, debounceTime, firstValueFrom, Subject, Subscription } from 'rxjs';
import { OrderService } from '@mocli/MocliCommonLib';
import { ActivatedRoute, Router } from '@angular/router';
import { ExperienceConfiguration } from 'src/app/data/models/ExperienceConfiguration';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalIonicConfirmPrimeComponent } from '../shared/modal/modal-ionic-confirm-prime/modal-ionic-confirm-prime.component';
import { ModalController } from '@ionic/angular';
import { Dropdown } from 'primeng/dropdown';
import { ModalConfirmComponent } from '../shared/modal/modal-confirm/modal-confirm.component';
import { ModalUrlSocialsTooltipComponent } from '../shared/modal/modal-url-socials-tooltip/modal-url-socials-tooltip.component';
import { AuthService } from 'src/app/services/AuthService';
import { ExperienceOrder } from '@mocli/MocliCommonLib/lib/data/models/ExperienceOrder';

export enum SocialsElementKey {
    GOOGLE = 'google',
    INSTAGRAM = 'instagram',
}
export enum SocialsStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export interface SocialsElement {
    key: SocialsElementKey;
    url: string;
    points: number;
    status: BadgeStatus;
    idx: number;
}

export interface SocialsHistoryContentEditor {
    config: any;
    metadata: SocialsHistoryMetaData;
}

export interface SocialsHistoryMetaData {
    idx?: number;
    inputName?: string;
    key: string;
}



@Component({
    selector: 'app-socials',
    templateUrl: './socials.component.html',
    styleUrl: './socials.component.scss'
})
export class SocialsComponent {
    orderId: number;
    order: ExperienceOrder = null;
    orderConfig: ExperienceConfiguration<any>;
    config: any = null;

    selectedSocial: SocialsElement = null;

    socialsList: SocialsElement[] = [];

    configHistoryStack: SocialsHistoryContentEditor[] = [];
    redoHistoryStack: SocialsHistoryContentEditor[] = [];

    undoRedoSubject: Subject<string> = new Subject<string>();

    isFormSubmitted: boolean = false;
    isAutoSaving: boolean = false;
    isUpdating: boolean = false;

    noHistoryTemp: boolean = false;

    editorMode: boolean = false;
    afterEditorMode: boolean = false;

    socialsFormGroup: FormGroup = null;

    saveStatus: boolean[] = [];
    changesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    editorValueChangesSubscription: Subscription = null;
    editorValuePlatformChangesDebounceTimeSubscription: Subscription = null;
    editorValuePointsChangesDebounceTimeSubscription: Subscription = null;
    editorValueUrlChangesDebounceTimeSubscription: Subscription = null;

    socialPlatformNameByElementKey = {
        [SocialsElementKey.GOOGLE]: 'Google Avis',
        [SocialsElementKey.INSTAGRAM]: 'Instagram',
    };

    // platformsList: { name: string; key: SocialsElementKey; disable: boolean }[] =
    // [
    //     {
    //         name: this.socialPlatformNameByElementKey[SocialsElementKey.GOOGLE],
    //         key: SocialsElementKey.GOOGLE,
    //         disable: false
    //     },
    //     {
    //         name: this.socialPlatformNameByElementKey[SocialsElementKey.INSTAGRAM],
    //         key: SocialsElementKey.INSTAGRAM,
    //         disable: false
    //     },
    // ];

    get platformsList() {
        return this.getPlatformList();
    }

    pointsList: { name: string; key: number; disable: boolean }[] =
        [
            {
                name: '10',
                key: 10,
                disable: false,
            },
            {
                name: '25',
                key: 25,
                disable: false,
            },
            {
                name: '50',
                key: 50,
                disable: false,
            },
            {
                name: '100',
                key: 100,
                disable: false,
            },
            {
                name: '200',
                key: 200,
                disable: false,
            },
            {
                name: '300',
                key: 300,
                disable: false,
            },
            {
                name: '500',
                key: 500,
                disable: false,
            },
        ];

    selectedPlatform: SocialsElementKey = null;


    get undoDisabled(): boolean {
        return this.configHistoryStack.length <= 0;
    };
    get redoDisabled(): boolean {
        return this.redoHistoryStack.length <= 0;
    }

    @ViewChild('dropdownPlatform') dropdownPlatform: Dropdown;
    @ViewChild('dropdownPoints') dropdownPoints: Dropdown;

    @HostListener('window:keydown', ['$event'])
    async onKeyDown(event: KeyboardEvent) {
        const hasModal = await this.modalController.getTop();

        if (!hasModal && (event.metaKey || event.ctrlKey) && (event.key === 'z' || event.key === 'Z') && !event.shiftKey) {
            event.preventDefault();
            this.onUndoClick();
        }

        if (!hasModal && (event.metaKey || event.ctrlKey) && event.shiftKey && (event.key === 'z' || event.key === 'Z')) {
            event.preventDefault();
            this.onRedoClick();
        }
    }

    constructor(
        private headerBarService: HeaderBarService,
        private orderService: OrderService,
        private route: ActivatedRoute,
        private router: Router,
        private modalController: ModalController,
        private formBuilder: FormBuilder,
        private authService: AuthService
    ) {

    }

    async ionViewWillEnter() {
        this.orderId = +this.route.snapshot.params.id;

        this.setupHeader();

        await this.getOrderConfig();

        if (!this.config) {
            this.router.navigateByUrl('/projects');
        }
    }


    async setupHeader() {
        await this.getOrder();

        this.headerBarService.onHeaderBarChanged.next({
            ...this.headerBarService.onHeaderBarChanged.value,
            title: this.order?.experienceInfo?.name ?? 'titre de l\'expérience',
            secondTitle: 'Engagement récompensé',
            pageDescription: 'Récoltez des abonnés ou des avis sur votre entreprise en donnant des points bonus sur le jeu.',
            isTitlePage: true,
            showBackBtn: true,
            showWallet: false,
        });
    }

    async getOrder() {
        if (!this.orderId || !this.authService?.userLogged?.value?.id) return;

        try {
          const res = await firstValueFrom(this.orderService.getOrderById(this.authService?.userLogged?.value?.id, this.orderId));

          this.order = res;
        } catch (error) {
          console.error('error on getOrder', error);
        }
      }

    async getOrderConfig() {
        if (!this.orderId) return;

        try {
            this.orderConfig = await firstValueFrom(this.orderService.getOrderConfiguration(this.orderId));

            if (this.orderConfig?.configuration?.ModularRoot?.configuration) {
                this.config = JSON.parse(JSON.stringify(this.orderConfig?.configuration?.ModularRoot?.configuration));
            }

            if (this.config?.socials) {
                this.socialsList = JSON.parse(JSON.stringify(this.config?.socials));
            }
        } catch (error) {
            console.log('error while fetching order', error);
        }
    }

    getPlatformList() {
        return [
            {
                name: this.socialPlatformNameByElementKey[SocialsElementKey.GOOGLE],
                key: SocialsElementKey.GOOGLE,
                disable: this.socialsList.find(c => c.key === SocialsElementKey.GOOGLE) ? true : false
            },
            {
                name: this.socialPlatformNameByElementKey[SocialsElementKey.INSTAGRAM],
                key: SocialsElementKey.INSTAGRAM,
                disable: this.socialsList.find(c => c.key === SocialsElementKey.INSTAGRAM) ? true : false
            },
        ];
    }

    onStatusChanged(status: BadgeStatus, social: SocialsElement) {
        this.isFormSubmitted = true;

        if (!status || !social) return;

        const idx = this.socialsList.findIndex(c => c.idx === social.idx);
        if (idx >= 0) {
            let metaData: any = null;
            if (this.selectedSocial) {
                metaData = { idx: this.selectedSocial.idx };
            }
            this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.socialsList)), metaData, true);
            this.socialsList[idx].status = status;

            this.saveContent(true);
        }
    }

    registerNewHistoryEvent(config: any, metadata: any, noSave: boolean = false) {
        this.configHistoryStack.push({ config, metadata });
        this.redoHistoryStack = [];

        if (!noSave) {
            this.saveContent(true);
        }
    }

    autoSavingCount = 0;
    async saveContent(isAutoSave: boolean = false, force: boolean = false) {
        this.isFormSubmitted = true;

        // if (this.socialsFormGroup && this.socialsFormGroup.invalid && !force) return;

        if (isAutoSave) {

            let timer = 0;

            if (this.isAutoSaving) {
                this.isAutoSaving = false;
                timer = 1;
            }
            setTimeout(() => {
                this.isAutoSaving = true;
            }, timer);
            this.autoSavingCount++;
        }

        const autoSavingCountTmp = JSON.parse(JSON.stringify(this.autoSavingCount));

        // sauvegarde des champs actifs sur la page actuelle
        this.saveContentLocal();

        const modularRoot = JSON.parse(JSON.stringify(this.orderConfig?.configuration?.ModularRoot));

        if (modularRoot?.configuration) {
            modularRoot.configuration.socials = JSON.parse(JSON.stringify(this.socialsList));
        }

        try {
            await firstValueFrom(this.orderService.configureGenericExperience(this.orderId, { ModularRoot: modularRoot }));

            this.changesSubject.next(false);

            if (isAutoSave) {
                setTimeout(() => {
                    if (this.autoSavingCount === autoSavingCountTmp) {
                        this.isAutoSaving = false;
                    }
                }, 2000);
            }
        } catch (error) {
            if (isAutoSave) {
                this.isAutoSaving = false;
            }
            console.log('error while saving content of codehunt configurator', error);
        }

    }

    saveContentLocal() {
        const idx = this.socialsList.findIndex(c => c.idx === this.selectedSocial?.idx);
        if (idx >= 0 && this.socialsList[idx]) {

            this.socialsList[idx].key = this.socialsFormGroup.get('platform').value;
            this.socialsList[idx].points = this.socialsFormGroup.get('points').value;
            this.socialsList[idx].url = this.socialsFormGroup.get('url').value;
        }
    }

    async onDeleteSocialClicked() {
        const props: any = {
            paramTitle: 'Voulez-vous vraiment supprimer ce lien ?',
        };

        const modal = await this.modalController.create({
            component: ModalConfirmComponent,
            componentProps: props,
            cssClass: 'modal-confirm-custom',
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.data === 'confirm') {
                // delete code from list
                const socialIdx = this.socialsList.findIndex(c => c.idx === this.selectedSocial.idx);

                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.socialsList)), { idx: this.selectedSocial.idx, key: socialIdx === 0 ? 'delete-first-element' : 'delete-element' }, true);

                this.socialsList = this.socialsList.filter(c => c.idx !== this.selectedSocial.idx);

                this.saveContent(true, true);

                // selecte first code
                if (this.socialsList.length > 0) {
                    this.socialsList?.forEach((c, idx) => {
                        c.idx = idx;
                    });
                    this.onEditClick(this.socialsList[0]);
                } else {
                    this.selectedSocial = null;
                    this.editorMode = false;
                    this.afterEditorMode = false;
                }
            }
        });
        return await modal.present();
    }

    editClickCount: number = 0;
    onEditClick(social: SocialsElement, inputToFocus: string = null, forceNoSaveStatus: boolean = false) {
        if (!social) return;

        // on active le mode d'update pour afficher le blur sur le contenu
        if (this.editorMode && this.selectedSocial !== social) this.isUpdating = true;

        // on active le mode d'édition pour afficher le contenu et non la vue d'ensemble
        this.editorMode = true;
        setTimeout(() => {
            this.afterEditorMode = true;
        }, 500);
        this.editorMode = true;

        // système pour savoir si on est déjà en train d'afficher le blur
        this.editClickCount++;
        const editTmp = JSON.parse(JSON.stringify(this.editClickCount));

        // s'il y a eu des changements, on les save avant de changer de code (en local, par encore en db)
        if (this.changesSubject.value === true) {
            this.saveContentLocal();
        }

        if (this.selectedSocial && this.selectedSocial.idx !== social.idx && !forceNoSaveStatus) {
            this.saveStatus[this.selectedSocial?.idx] = true;
        }

        // scroll au top de l'élement id codehuntContentMainContainer
        const element = document.getElementById('codehuntContentMainContainer');
        if (element) {
            element.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // on attends 200ms pour attendre l'animation de blur avant de charger le contenu
        setTimeout(() => {
            // on change le code sélectionné
            this.selectedSocial = social;

            // initialisation du formGroup
            if (!this.socialsFormGroup) this.initFormGroup(social);
            else this.updateFormGroup(social);
        }, 200);

        // on désactive le mode d'update après 400ms si on n'a pas cliqué sur un autre code
        setTimeout(() => {
            if (this.editClickCount === editTmp) {
                this.isUpdating = false;

                if (inputToFocus) {
                    if (inputToFocus === 'platform') {
                        this.dropdownPlatform.focused = true;
                        this.dropdownPlatform.applyFocus();
                    }
                    else if (inputToFocus === 'points') {
                        this.dropdownPoints.focused = true;
                        this.dropdownPoints.applyFocus();
                    }
                    else {
                        const inputElement = document.getElementById('input-' + inputToFocus);
                        inputElement?.focus();
                    }
                }
            }
        }, 400);
    }

    async openModalPendingChanges() {
        const modal = await this.modalController.create({
            component: ModalIonicConfirmPrimeComponent,
            cssClass: 'modal-login',
            backdropDismiss: false,
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned?.data === 'leave') {
            }
        });

        return await modal.present();
    }

    addNewElement() {
        if (this.socialsList?.length === this.getPlatformList()?.length) return;

        const socialsListTmp = JSON.parse(JSON.stringify(this.socialsList));
        let newSocial = null;

        newSocial = {
            key: null,
            url: '',
            points: 0,
            status: BadgeStatus.ACTIVE,
            idx: socialsListTmp.length,
        };

        this.registerNewHistoryEvent(socialsListTmp, { key: 'add-element' }, true);

        this.socialsList.push(newSocial);

        this.redoHistoryStack = [];
        this.onEditClick(this.socialsList[this.socialsList.length - 1]);
        setTimeout(() => {
            this.saveContent(true);
        }, 200);
    }

    hasErrorSocial(social: SocialsElement) {
        if (!social) return false;

        if (social?.status === BadgeStatus.INACTIVE) return false;

        let invalid = false;
        if (!social?.key) invalid = true;
        if (invalid) return true;

        return !social?.points || !social?.url || social?.url?.length <= 0 || social?.url === '';
    }

    /* -------------- FormGroup -------------- */

    initFormGroup(social: SocialsElement = null) {
        // on charge le contenu du code sélectionné
        const codeIdx = this.socialsList.findIndex(c => c.idx === social?.idx);

        const platformsEnabled = this.getPlatformList()?.filter(platform => platform.disable === false);
        let firstPlatformEnabled = null;

        if (platformsEnabled?.length > 0) {
            firstPlatformEnabled = platformsEnabled[0]?.key;
        }

        this.socialsFormGroup = this.formBuilder.group({
            platform: [social?.key ?? firstPlatformEnabled ?? null, [Validators.required]],
            points: [social?.points > 0 ? social.points : 100, [Validators.required]],
            url: [social?.url ?? null, [Validators.required]],
        });

        // on désactive l'ancien système de détection de changements
        if (this.editorValueChangesSubscription) this.editorValueChangesSubscription.unsubscribe();

        // on active le système de détection de changements
        this.editorValueChangesSubscription = this.socialsFormGroup.valueChanges.subscribe(() => {
            this.changesSubject.next(true);
        });

        this.editorValuePlatformChangesDebounceTimeSubscription = this.socialsFormGroup.get('platform')?.valueChanges.pipe(debounceTime(500)).subscribe((event: any) => {
            if (this.noHistoryTemp) return;
            this.onValueChange('platform');
        });

        this.editorValuePointsChangesDebounceTimeSubscription = this.socialsFormGroup.get('points')?.valueChanges.pipe(debounceTime(500)).subscribe((event: any) => {
            if (this.noHistoryTemp) return;
            this.onValueChange('points');
        });

        this.editorValueUrlChangesDebounceTimeSubscription = this.socialsFormGroup.get('url')?.valueChanges.pipe(debounceTime(500)).subscribe((event: any) => {
            if (this.noHistoryTemp) return;
            this.onValueChange('url');
        });
    }

    onValueChange(inputName: string) {
        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.socialsList)), { idx: this.selectedSocial?.idx, inputName });
    }

    updateFormGroup(social: SocialsElement = null) {
        if (!social) return;

        const platformsEnabled = this.getPlatformList()?.filter(platform => platform.disable === false);
        let firstPlatformEnabled = null;

        if (platformsEnabled?.length > 0) {
            firstPlatformEnabled = platformsEnabled[0]?.key;
        }

        this.socialsFormGroup.reset({
            platform: social?.key ?? firstPlatformEnabled ?? null,
            points: social?.points > 0 ? social.points : 100,
            url: social?.url ?? null,
        }, { emitEvent: false });
    }

    onPlatformChanged() {
        this.selectedPlatform = this.socialsFormGroup.get('platform').value;
    }

    waitingTiming = 400;
    onUndoClick() {
        if (this.configHistoryStack.length <= 0) return;

        // on active le mode changement d'historique pour ne pas trigger les onValueChanges des input
        this.noHistoryTemp = true;

        // on garde la config actuelle
        const currentConfig = JSON.parse(JSON.stringify(this.socialsList));

        // on récupère la dernière config de l'historique
        const historyConfig = this.configHistoryStack.pop();

        // s'il y a des métadonnées, on les garde
        const metaData: any = { ...historyConfig?.metadata };
        if (historyConfig.metadata && historyConfig.metadata?.idx !== null) metaData.idx = historyConfig.metadata.idx;

        // on ajoute la config actuelle à l'historique de redo
        this.redoHistoryStack.push({ config: currentConfig, metadata: metaData });

        let timeout = 0;

        if (metaData?.key === 'add-element') {
            if (this.socialsList?.length <= 1) {
                this.editorMode = false;
                this.afterEditorMode = false;
            } else {
                // go to the last element of the list
                this.saveStatus[this.selectedSocial?.idx] = false;
                this.onEditClick(this.socialsList[this.socialsList.length - 2], null, true);
                timeout = 200;
            }
        }

        // si on a des métadonnées de code, on change le code sélectionné
        if (historyConfig?.metadata?.idx !== null && historyConfig.metadata?.idx !== this.selectedSocial?.idx) {
            const social = this.socialsList.find(c => c.idx === historyConfig.metadata?.idx);

            if (social) {
                this.onEditClick(social);
                timeout = this.waitingTiming;
            }
        }

        // on attends soit 0 soit 400ms pour changer la config (durée de l'animation de navigation entre les codes)
        setTimeout(() => {
            // on change la config actuelle par la config de l'historique
            this.socialsList = historyConfig.config;

            // on update le code sélectionné
            this.selectedSocial = this.socialsList.find(c => c.idx === this.selectedSocial?.idx);

            if (metaData?.key === 'delete-first-element') {
                // go to the selected element
                this.onEditClick(this.socialsList[0], null, true);
            }

            // si on a des métadonnées de code, on initialise le formGroup
            if (historyConfig?.metadata && historyConfig?.metadata?.idx !== null) {
                const social = this.socialsList.find(c => c.idx === historyConfig.metadata?.idx);

                if (this.selectedSocial?.idx === social?.idx) {
                    this.initFormGroup(social);
                }
            }

            this.saveContent(true);

            // si on a des métadonnées d'input, on focus sur l'input
            if (historyConfig?.metadata && historyConfig?.metadata?.inputName) {
                this.onEditClick(this.selectedSocial, null, true);

                if (historyConfig.metadata.inputName === 'platform') {
                    this.dropdownPlatform.focused = true;
                    this.dropdownPlatform.applyFocus();
                }
                else if (historyConfig.metadata.inputName === 'points') {
                    this.dropdownPoints.focused = true;
                    this.dropdownPoints.applyFocus();
                }
                else {
                    const inputElement = document.getElementById('input-' + historyConfig.metadata.inputName);
                    inputElement?.focus();
                }
            } else if (this.editorMode === true) {
                this.dropdownPlatform.focused = false;
                this.dropdownPoints.focused = false;
            }

            // on enlève le mode changement d'historique après 600ms
            setTimeout(() => {
                this.noHistoryTemp = false;
            }, 600);
        }, timeout);
    }

    onRedoClick() {
        if (this.redoHistoryStack.length <= 0) return;

        // on active le mode changement d'historique pour ne pas trigger les onValueChanges des input
        this.noHistoryTemp = true;

        // on garde la config actuelle
        const currentConfig = JSON.parse(JSON.stringify(this.socialsList));

        // on récupère la dernière config de l'historique
        const historyConfig = this.redoHistoryStack.pop();

        let timeout = 0;

        // si on a des métadonnées de code, on change le code sélectionné
        if (historyConfig?.metadata?.idx !== null && historyConfig.metadata?.idx !== this.selectedSocial?.idx && historyConfig.metadata?.key !== 'delete-first-element' && historyConfig.metadata?.key !== 'delete-element') {
            this.onEditClick(this.socialsList.find(c => c.idx === historyConfig.metadata?.idx));
            timeout = this.waitingTiming;
        }

        // on ajoute la config actuelle à l'historique de undo
        this.configHistoryStack.push({ config: currentConfig, metadata: { ...historyConfig.metadata } });

        // on attends soit 0 soit waitingTime pour changer la config (durée de l'animation de navigation entre les codes)
        setTimeout(() => {
            // on change la config actuelle par la config de l'historique
            this.socialsList = historyConfig.config;
            // on update le code sélectionné
            this.selectedSocial = this.socialsList.find(c => c.idx === this.selectedSocial?.idx);

            const code = this.socialsList.find(c => c.idx === historyConfig.metadata?.idx);

            if (historyConfig.metadata?.key === 'delete-first-element' || historyConfig.metadata?.key === 'delete-element') {
                // go to the selected element
                setTimeout(() => {
                    this.onEditClick(this.socialsList[0], null, true);
                }, 1);
            }

            // si on a des métadonnées de code, on initialise le formGroup
            if (historyConfig.metadata?.idx !== null) {
                if (this.selectedSocial?.idx === code?.idx) {
                    this.initFormGroup(code);
                }
            }

            this.saveContent(true);

            // si on a des métadonnées d'input, on focus sur l'input
            if (historyConfig?.metadata?.inputName) {
                this.onEditClick(this.selectedSocial, historyConfig?.metadata?.inputName, true);

                if (historyConfig.metadata.inputName === 'platform') {
                    this.dropdownPlatform.focused = true;
                    this.dropdownPlatform.applyFocus();
                }
                else if (historyConfig.metadata.inputName === 'points') {
                    this.dropdownPoints.focused = true;
                    this.dropdownPoints.applyFocus();
                }
                else {
                    const inputElement = document.getElementById('input-' + historyConfig.metadata.inputName);
                    inputElement?.focus();
                }
            } else {
                if (this.editorMode) {
                    this.dropdownPlatform.focused = false;
                    this.dropdownPoints.focused = false;
                }
            }

            // on enlève le mode changement d'historique après 600ms
            setTimeout(() => {
                this.noHistoryTemp = false;
            }, 600);
        }, timeout);
    }

    async openUrlTooltip(isPreview: boolean = null) {
        const modal = await this.modalController.create({
            component: ModalUrlSocialsTooltipComponent,
            componentProps: { socialElementKey: isPreview ? 'preview' : this.socialsFormGroup.get('platform')?.value },
            cssClass: 'modal-url-socials-tooltip',
        });

        return await modal.present();
    }

}
