/* eslint-disable max-len */
import { firstValueFrom } from 'rxjs';
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/AuthService';
import { HeaderBarService } from '../services/HeaderBarService';
import { OrderService } from '@mocli/MocliCommonLib';

const additionalOptionByUrl = {
  data: 'userForm',
  prize: 'prizeDraw',
  socials: 'socials',
};

@Injectable({ providedIn: 'root' })
export class AdditionalOptionsGuard  {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private orderService: OrderService,
    private headerBar: HeaderBarService
  ) {}

  async canActivate( routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Promise<boolean | UrlTree | Observable<boolean | UrlTree>> {

    // Expression régulière pour extraire les numéros
    const pattern = /\/(\d+)$/;
    const match = state?.url?.match(pattern);
    const id: number = match ? JSON.parse(match[1]) : null;

    if (id && this.authService?.userLogged?.value?.id) {

      try {
        const res: any = await firstValueFrom(this.orderService.getSubscribedOrderOptions(this.authService.userLogged.value.id, id));

        if (res?.options?.length <= 0) return this.navigateTo('/projects');

        if (Object.keys(res.options).includes(additionalOptionByUrl[routeSnapshot?.routeConfig?.path])) {
          return true;
        }

        return this.navigateTo('/projects');
      } catch (error) {
        console.error('Error while getting additionnal order options',error);
        return this.navigateTo('/projects');
      }

    } else {
      return this.navigateTo('/projects');
    }
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
    return false;
  }

  setHeaderBarEmpty() {
    this.headerBar.updateHeaderBar({
      ...this.headerBar.onHeaderBarChanged.value,
      title: '',
      showBackBtn: false,
    });
  }
}
