<div class="content-editor-main-container">

    <div class="codehunt-content-main-container mocli-box-content" #codehuntContentMainContainer
        id="codehuntContentMainContainer">

        <div class="error-blocker error-blocker-left"></div>
        <div class="error-blocker error-blocker-right"></div>
        
        <div class="configurator-layer">
            <!-- Configurateur -->
            <div class="configurator-container" [ngClass]="{'configurator-container-overview': !editorMode}">
                <div class="configurator-title-container">
                    <div class="status-badge-container">
                        <app-status-badge (onStatusChanged)="onStatusChanged($event, selectedSocial)"
                            [status]="selectedSocial?.status"></app-status-badge>
                    </div>

                    <div class="delete-code-container" (click)="onDeleteSocialClicked()">Supprimer ce lien</div>
                </div>

                <!-- Plateforme -->
                <div class="configurator-block" *ngIf="socialsFormGroup" id="mInput_select" [formGroup]="socialsFormGroup" style="max-width: 190px;">
                    <div class="url-label-container">

                        <app-input-label class="label-questions" label="Plateforme"
                            [errors]="isFormSubmitted === true && saveStatus[selectedSocial?.idx] === true && socialsFormGroup?.get('platform')?.value === null ? {required: true} : null"
                        ></app-input-label>
                        <div class="url-tooltip" (click)="openUrlTooltip(true)">voir un aperçu</div>
                    </div>
                    <p-dropdown #dropdownPlatform [autoDisplayFirst]="true" [virtualScroll]="true" [virtualScrollItemSize]="28" formControlName="platform" scrollHeight="100px" optionLabel="name" optionValue="key" optionDisabled="disable" [options]="platformsList" (onChange)="onPlatformChanged()"></p-dropdown>
                </div>

                <div class="configurator-block" *ngIf="socialsFormGroup" id="mInput_select" [formGroup]="socialsFormGroup" style="max-width: 190px;">
                    <app-input-label class="label-questions" label="Points à gagner"
                        [errors]="isFormSubmitted === true && saveStatus[selectedSocial?.idx] === true && (socialsFormGroup?.get('points')?.value === null || socialsFormGroup?.get('points')?.value <= 0) ? {required: true} : null"
                    ></app-input-label>
                    <p-dropdown #dropdownPoints [autoDisplayFirst]="true" [virtualScroll]="true" [virtualScrollItemSize]="24" formControlName="points" scrollHeight="300px" optionLabel="name" optionValue="key" optionDisabled="disable" [options]="pointsList"></p-dropdown>
                </div>

                <div class="configurator-block" *ngIf="socialsFormGroup" [formGroup]="socialsFormGroup">
                    <div class="url-label-container">
                        <app-input-label [label]="socialsFormGroup?.get('platform')?.value === 'google' ? 'Lien' : 'Nom de la page'"></app-input-label>
                        <div class="url-tooltip" (click)="openUrlTooltip()">où le trouver</div>
                    </div>
                    <app-input-text
                        [inputFormGroup]="socialsFormGroup"
                        [inputFormControlName]="'url'"
                        [placeholder]="'Écrire ici...'"
                        customId="input-url"
                        [isFormSubmitted]="isFormSubmitted && saveStatus[selectedSocial?.idx] === true"
                    ></app-input-text>
                </div>

            </div>

            <div class="configurator-transition" [ngClass]="{'configurator-transition-active': isUpdating}"></div>
        </div>


        <div class="top-layer-container" [ngClass]="{'top-layer-container-afterEditorMode': afterEditorMode === true}">


            <div class="top-layer">

                <div class="left-container">
                    <div class="codehunt-content-header"
                        (click)="editorMode = false; saveStatus[selectedSocial?.idx] = true; afterEditorMode = false">
                        <div class="codehunt-content-header_title">Lien(s)</div>
                    </div>

                    <div class="codehunt-content-table">
                        <div class="codehunt-content-row codehunt-content-row-code"
                            *ngFor="let social of socialsList; let socialIdx = index" (click)="onEditClick(social)">
                            <div class="codehunt-content-item code"
                                [ngClass]="{'code-no-border': editorMode && (selectedSocial?.idx === socialIdx), 'code-odd': socialIdx % 2 === 0, 'code-even': socialIdx % 2 === 1, 'code-selected': editorMode && selectedSocial?.idx === socialIdx}">
                                Lien {{socialIdx + 1}}
                            </div>
                        </div>
                        <div class="selected-row" [style]="'top: ' + (selectedSocial?.idx * 33 - 1 > 0 ? selectedSocial?.idx * 33 - 1 : 0) + 'px'"
                            [ngClass]="{'selected-row-disabled': !editorMode || !selectedSocial}"></div>

                        <div class="codehunt-content-row bg-white">
                            <div class="codehunt-content-item add-btn" [ngClass]="{'add-btn-disabled': socialsList?.length === getPlatformList()?.length}" (click)="addNewElement()">
                                <div class="add-btn-text-image">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_8220_17313)">
                                            <circle cx="12" cy="10" r="8" fill="white" />
                                            <path
                                                d="M12 2C10.4178 2 8.87103 2.46919 7.55544 3.34824C6.23985 4.22729 5.21447 5.47672 4.60897 6.93853C4.00347 8.40034 3.84504 10.0089 4.15372 11.5607C4.4624 13.1126 5.22433 14.538 6.34315 15.6569C7.46197 16.7757 8.88743 17.5376 10.4393 17.8463C11.9911 18.155 13.5997 17.9965 15.0615 17.391C16.5233 16.7855 17.7727 15.7602 18.6518 14.4446C19.5308 13.129 20 11.5822 20 10C20 7.87827 19.1571 5.84344 17.6569 4.34315C16.1566 2.84285 14.1217 2 12 2ZM12 16.8571C10.6438 16.8571 9.31803 16.455 8.19038 15.7015C7.06273 14.948 6.18383 13.8771 5.66483 12.6241C5.14583 11.3711 5.01003 9.99239 5.27462 8.66224C5.5392 7.33208 6.19228 6.11026 7.15127 5.15127C8.11026 4.19228 9.33209 3.5392 10.6622 3.27461C11.9924 3.01003 13.3711 3.14582 14.6241 3.66483C15.8771 4.18383 16.948 5.06272 17.7015 6.19037C18.455 7.31803 18.8571 8.64378 18.8571 10C18.8571 11.8186 18.1347 13.5628 16.8487 14.8487C15.5628 16.1347 13.8186 16.8571 12 16.8571ZM16 10C16 10.1516 15.9398 10.2969 15.8326 10.4041C15.7255 10.5112 15.5801 10.5714 15.4286 10.5714H12.5714V13.4286C12.5714 13.5801 12.5112 13.7255 12.4041 13.8326C12.2969 13.9398 12.1516 14 12 14C11.8485 14 11.7031 13.9398 11.5959 13.8326C11.4888 13.7255 11.4286 13.5801 11.4286 13.4286V10.5714H8.57143C8.41988 10.5714 8.27453 10.5112 8.16737 10.4041C8.06021 10.2969 8 10.1516 8 10C8 9.84845 8.06021 9.7031 8.16737 9.59594C8.27453 9.48877 8.41988 9.42857 8.57143 9.42857H11.4286V6.57143C11.4286 6.41988 11.4888 6.27453 11.5959 6.16737C11.7031 6.0602 11.8485 6 12 6C12.1516 6 12.2969 6.0602 12.4041 6.16737C12.5112 6.27453 12.5714 6.41988 12.5714 6.57143V9.42857H15.4286C15.5801 9.42857 15.7255 9.48877 15.8326 9.59594C15.9398 9.7031 16 9.84845 16 10Z"
                                                fill="#002852" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_8220_17313" x="0" y="0" width="24" height="24"
                                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="2" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_8220_17313" />
                                                <feBlend mode="normal" in="SourceGraphic"
                                                    in2="effect1_dropShadow_8220_17313" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </div>
                                <div class="add-btn-text">Ajouter un lien</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right-container"
                    [ngClass]="{'right-container-editor': editorMode === true, 'right-container-afterEditorMode': afterEditorMode === true}">
                    <!-- Vue d'ensemble -->
                    <div class="overview-container">
                        <div class="codehunt-content-header">
                            <div class="codehunt-content-header-item codehunt-content-header_edit"></div>
                            <div class="codehunt-content-header-item codehunt-content-header_questions">
                                Plateforme
                            </div>
                            <div class="codehunt-content-header-item codehunt-content-header_periods">
                                Points
                            </div>
                            <div class="codehunt-content-header-item codehunt-content-header_status">
                                Statut
                                <div class="tooltip-info"
                                    pTooltip="Un lien désactivé n'apparaîtra pas dans la page de points bonus sur le jeu mobile."
                                    tooltipPosition="left">i</div>
                            </div>
                        </div>


                        <!-- Tableau (langue principale) -->
                        <div class="codehunt-content-table">
                            <div class="codehunt-content-row" *ngFor="let social of socialsList; let i = index">
                                <div class="codehunt-content-item codehunt-content-item_edit">
                                    <app-edit-button (editButtonClicked)="onEditClick(social)"></app-edit-button>
                                </div>
                                <div class="codehunt-content-item codehunt-content-item_questions"
                                    [ngClass]="{'codehunt-content-item-hover': !editorMode}" (click)="onEditClick(social)">
                                    {{socialPlatformNameByElementKey[social?.key]}}
                                </div>
                                <div class="codehunt-content-item codehunt-content-item_periods"
                                    (click)="onEditClick(social)">
                                    {{social?.points}}
                                </div>
                                <div class="codehunt-content-item codehunt-content-item_status">
                                    <app-status-badge (onStatusChanged)="onStatusChanged($event, social)"
                                        [status]="social?.status"></app-status-badge>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>


            <!-- List d'erreurs -->
            <ng-container *ngFor="let social of socialsList; let socialIdx = index">
                <div class="test-error" [style]="'top: ' + (50 + socialIdx * 33) + 'px'" [ngClass]="{
                        'test-error-active': hasErrorSocial(social),
                        'test-error-editor': editorMode === true,
                    }" (click)="hasErrorSocial(social) ? onEditClick(social) : null">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.68564 2.14572C8.26675 1.17381 9.67402 1.17165 10.2581 2.14176L16.631 12.7263C17.2329 13.726 16.5129 15 15.346 15H2.64453C1.47962 15 0.759302 13.7301 1.3571 12.7302L7.68564 2.14572Z"
                            fill="#D86C6C" />
                        <path
                            d="M9.78125 5.89062L9.62012 10.8271H8.44824L8.28223 5.89062H9.78125ZM8.24316 12.3555C8.24316 12.1471 8.31478 11.9746 8.45801 11.8379C8.60449 11.6979 8.79655 11.6279 9.03418 11.6279C9.27507 11.6279 9.46712 11.6979 9.61035 11.8379C9.75358 11.9746 9.8252 12.1471 9.8252 12.3555C9.8252 12.5573 9.75358 12.7282 9.61035 12.8682C9.46712 13.0081 9.27507 13.0781 9.03418 13.0781C8.79655 13.0781 8.60449 13.0081 8.45801 12.8682C8.31478 12.7282 8.24316 12.5573 8.24316 12.3555Z"
                            fill="white" />
                    </svg>
                </div>
            </ng-container>
        </div>
    </div>

</div>


<div class="content-editor-controls-container">
    <div class="undo-redo-container">
        <div class="undo-container" (click)="undoDisabled ? null : onUndoClick()"
            [ngClass]="{'undo-container-disabled': undoDisabled}">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.2875 2.95996V1.53125C1.2875 1.4054 1.24511 1.29275 1.16368 1.21132C1.08225 1.12989 0.9696 1.0875 0.84375 1.0875C0.7179 1.0875 0.605255 1.12989 0.523821 1.21132C0.442386 1.29275 0.4 1.4054 0.4 1.53125V4.28125C0.4 4.4071 0.442386 4.51975 0.523821 4.60118C0.605255 4.68261 0.7179 4.725 0.84375 4.725H3.59375C3.7196 4.725 3.83225 4.68261 3.91368 4.60118C3.99511 4.51975 4.0375 4.4071 4.0375 4.28125C4.0375 4.1554 3.99511 4.04275 3.91368 3.96132C3.83225 3.87989 3.7196 3.8375 3.59375 3.8375H1.82769C2.59895 2.3025 4.23597 1.2875 6 1.2875C8.62602 1.2875 10.7125 3.37398 10.7125 6C10.7125 8.62602 8.62602 10.7125 6 10.7125C4.72009 10.7125 3.57838 10.2418 2.70453 9.3007L2.70458 9.30066L2.70196 9.29804C2.52541 9.12149 2.25584 9.12149 2.07929 9.29804C1.90314 9.47419 1.90274 9.74294 2.07809 9.91951C3.12914 11.04 4.53061 11.6 6 11.6C9.08023 11.6 11.6 9.08023 11.6 6C11.6 2.91977 9.08023 0.4 6 0.4C4.09242 0.4 2.30897 1.38862 1.2875 2.95996Z"
                    fill="#848484" stroke="#848484" stroke-width="0.2" />
            </svg>
        </div>
        <div class="redo-container" (click)="redoDisabled ? null : onRedoClick()"
            [ngClass]="{'undo-container-disabled': redoDisabled}">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.7125 2.95996V1.53125C10.7125 1.4054 10.7549 1.29275 10.8363 1.21132C10.9178 1.12989 11.0304 1.0875 11.1562 1.0875C11.2821 1.0875 11.3947 1.12989 11.4762 1.21132C11.5576 1.29275 11.6 1.4054 11.6 1.53125V4.28125C11.6 4.4071 11.5576 4.51975 11.4762 4.60118C11.3947 4.68261 11.2821 4.725 11.1562 4.725H8.40625C8.2804 4.725 8.16775 4.68261 8.08632 4.60118C8.00489 4.51975 7.9625 4.4071 7.9625 4.28125C7.9625 4.1554 8.00489 4.04275 8.08632 3.96132C8.16775 3.87989 8.2804 3.8375 8.40625 3.8375H10.1723C9.40105 2.3025 7.76403 1.2875 6 1.2875C3.37398 1.2875 1.2875 3.37398 1.2875 6C1.2875 8.62602 3.37398 10.7125 6 10.7125C7.27991 10.7125 8.42162 10.2418 9.29547 9.3007L9.29542 9.30066L9.29804 9.29804C9.47459 9.12149 9.74416 9.12149 9.92071 9.29804C10.0969 9.47419 10.0973 9.74294 9.92191 9.91951C8.87086 11.04 7.46939 11.6 6 11.6C2.91977 11.6 0.4 9.08023 0.4 6C0.4 2.91977 2.91977 0.4 6 0.4C7.90758 0.4 9.69103 1.38862 10.7125 2.95996Z"
                    fill="#848484" stroke="#848484" stroke-width="0.2" />
            </svg>
        </div>
    </div>


    <div class="svg-loader">
        <div class="loader-text" [ngClass]="{'appear': isAutoSaving === true}">Sauvegardé</div>
        
        <svg class="loader">
            <!-- Le cercle qui se remplit -->
            <circle class="loader-circle" [ngClass]="{'fill-circle': isAutoSaving === true}" cx="10" cy="10" r="9" />
        </svg>

        <svg class="check" [ngClass]="{'appear': isAutoSaving === true}" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10289_85064)">
                <path
                    d="M3.6138 2.71767C5.28371 1.3484 7.42177 0.526367 9.75416 0.526367C15.0961 0.52626 19.4233 4.84067 19.4233 10.1589C19.4233 12.4828 18.598 14.6133 17.2224 16.2776C15.4756 18.3911 12.8086 19.4224 9.75416 19.4224C4.41733 19.4224 0.527344 15.4822 0.527344 10.1589C0.527344 7.10572 1.49711 4.45312 3.6138 2.71767Z"
                    fill="#92C24D" stroke="#92C24D" />
                <path
                    d="M8.32197 14.5368C8.08607 14.5368 7.85004 14.4456 7.66996 14.2632L4.44209 10.9934C4.0818 10.6284 4.0818 10.0373 4.44209 9.67234C4.80236 9.3074 5.38596 9.3074 5.74624 9.67234L8.32197 12.2815L14.1256 6.40262C14.4859 6.03767 15.0695 6.03767 15.4298 6.40262C15.79 6.76757 15.79 7.35873 15.4298 7.72368L8.97411 14.263C8.79391 14.4456 8.55789 14.5368 8.32197 14.5368Z"
                    fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_10289_85064">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    </div>
</div>
