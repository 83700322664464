/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ]
})
export class InputTextComponent implements ControlValueAccessor, Validator {
  @Input() inputFormGroup: FormGroup = null;
  @Input() inputFormControlName: string = null;
  @Input() placeholder: string = '';
  @Input() maxLength: number = null;
  @Input() label: string = null;
  @Input() type: 'text' | 'number' = 'text';
  @Input() min: number = null;
  @Input() max: number = null;
  @Input() labelBottom: number = 0;
  @Input() labelWidth: number = 0;
  @Input() tabIndex: number = null;
  @Input() optional: boolean = false;
  @Input() standalone: boolean = false;
  @Input() optionalFeminine: boolean = false;
  @Input() forcedError: boolean = false;
  @Input() labelDescription: string = null;
  @Input() labelTooltip: string = null;
  @Input() style: string = null;

  @Input() value: string = null;
  @Input() disabled: boolean = false;
  @Input() isFormSubmitted: boolean = true;

  @Input() forcedWidth: string = null;
  @Input() customId: string = null;

  // erreurs de l'input
  @Input() errors: any = null;

  // Méthode appelée lorsque la valeur change
  onChange: any = () => {};
  onTouched: any = () => {};

  get valueLength() {
    if (!this.inputFormGroup || !this.inputFormGroup.get(this.inputFormControlName)) return -1;

    return this.inputFormGroup.get(this.inputFormControlName)?.value?.length ?? 0;
  }

  // Reçoit la nouvelle valeur du parent (formControl)
  writeValue(value: string): void {
    this.value = value;
  }

  // Enregistre la fonction à appeler lorsque la valeur change dans le formulaire
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Enregistre la fonction à appeler lorsque l'input est touché
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Retourne les erreurs de validation du contrôle
  validate(control: AbstractControl): ValidationErrors | null {
    // Si la valeur du contrôle est invalide, appliquez la logique d'erreur ici
    if (control.errors) {
      this.errors = control.errors;
      return control.errors;
    } else {
      this.errors = null;
    }
    return null;
  }

  // Appelée lorsque l'utilisateur tape dans l'input
  onInputChange(input: any) {
    this.value = input.value;
    this.onChange(input.value); // Informe Angular du changement de valeur
    this.onTouched();     // Marque le champ comme touché

    const control = this.inputFormGroup?.get(this.inputFormControlName);

    if (control) {
      this.validate(control);
    }
  }
}
