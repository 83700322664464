import { SimpleQuiz } from 'src/app/data/models/SimpleQuiz';
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { environment } from 'src/environments/environment';
/* eslint-disable arrow-body-style */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StepsService } from 'src/app/features/steps/steps.service';
import { HeaderBarService } from 'src/app/services/HeaderBarService';
import { CodehuntStructureComponent } from './codehunt-structure/codehunt-structure.component';
import { BehaviorSubject, firstValueFrom, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CodeHunt, CodeHuntCode } from 'src/app/data/models/CodeHunt';
import { ClientExperienceService, ExperienceService, OrderService } from '@mocli/MocliCommonLib';
import { AuthService } from 'src/app/services/AuthService';
import { ExperienceOrder } from '@mocli/MocliCommonLib/lib/data/models/ExperienceOrder';
import { CodehuntContentEditorComponent } from './codehunt-content-editor/codehunt-content-editor.component';
import { MessageService } from 'primeng/api';
import { ModalController } from '@ionic/angular';
import { ModalConfirmPrimeComponent } from 'src/app/features/shared/modal/modal-confirm-prime/modal-confirm-prime.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalIonicConfirmPrimeComponent } from 'src/app/features/shared/modal/modal-ionic-confirm-prime/modal-ionic-confirm-prime.component';
import { StepItem } from 'src/app/features/steps/steps.component';
import { DateTime } from 'luxon';
import { BadgeStatus } from 'src/app/features/shared/status-badge/status-badge.component';

export interface CodeHuntSticker {
    name: string;
    quantity: number;
    points: number;
    imageUrl?: string;
}

@Component({
    selector: 'app-code-hunt-configurator',
    templateUrl: './code-hunt-configurator.component.html',
    styleUrls: ['./code-hunt-configurator.component.scss'],
})
export class CodeHuntConfiguratorComponent implements OnInit, OnDestroy {
    @ViewChild(CodehuntStructureComponent) codehuntStructureComponent!: CodehuntStructureComponent;
    @ViewChild(CodehuntContentEditorComponent) codeHuntContentEditorComponent!: CodehuntContentEditorComponent;

    undoRedoSubject: Subject<string> = new Subject<string>();

    config: any;
    configByLang: any[] = [];
    modularConfigByLang: any[] = [];
    codeHuntConfig: CodeHunt | any = null;
    structureConfig: any = null;
    order: ExperienceOrder = null;

    languages: string[] = ['fr'];
    baseLanguage: string = 'fr';

    saveSubject: Subject<{ ref: any; nextUrl: string }>;
    saveSubscription: Subscription = null;

    changesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isCompleteSubject: any;

    orderId: number = null;

    isSaving: boolean = false;
    isAutoSaving: boolean = false;
    hasAutoSaved: boolean = false;
    isMultiQuiz: boolean = false;
    isCodeHuntQuiz: boolean = false;

    undoDisabled: boolean = true;
    redoDisabled: boolean = true;

    editorMode: boolean = false;

    waitingAnimation: boolean = false;

    editorModeChanged: Subject<{state: boolean; initiator: 'parent' | 'child'}> = new Subject<{state: boolean; initiator: 'parent' | 'child'}>();
    editorModeChangedSubscription: Subscription = null;

    newCodeHuntConfiguration = null;

    pin = null;

    get languagesWithoutBase() {
        return this.languages?.filter((lang) => lang !== this.baseLanguage);
    }

    constructor(
        private headerBarService: HeaderBarService,
        private route: ActivatedRoute,
        private orderService: OrderService,
        private authService: AuthService,
        private experienceSrv: ExperienceService,
        private messageService: MessageService,
        private modalController: ModalController,
        private clientExperienceService: ClientExperienceService,

        public stepsService: StepsService
    ) { }


    /* ----------- Lifecycle ----------- */

    // INIT

    async ngOnInit(): Promise<void> {
        this.orderId = this.route.snapshot.params.id;

        if (this.config?.content[0]?.CodeHunt) this.codeHuntConfig = this.config?.content[0]?.CodeHunt;

        if (this.config?.configuration?.availableLanguages) {
            this.languages = this.config?.configuration?.availableLanguages;
            if (this.languages?.length > 1) {
                for (let i = 0; i < this.languages.length; i++) {
                    if (this.languages[i] === this.baseLanguage) continue;

                    await this.getConfigByLang(this.languages[i]);
                }
            }
        }

        this.getPinCode();

        if (this.codeHuntConfig?.isQuizLeaderboard) this.isMultiQuiz = true;
        if (this.codeHuntConfig?.isCodeHuntQuiz) this.isCodeHuntQuiz = true;

        this.structureConfig = {
            gameName: this.config?.configuration?.title ?? '',
            introText: this.codeHuntConfig?.introText ?? '',
            codesPoints: this.codeHuntConfig?.codesPoints ?? 50
        };

        if (this.isCodeHuntQuiz) {
            this.structureConfig.subExpAnswerScore = this.codeHuntConfig?.subExpAnswerScore ?? 50;
        }

        if (!this.isMultiQuiz) {
            this.structureConfig.searchArea = this.codeHuntConfig?.searchArea ?? '';
            this.structureConfig.stickerWord = this.codeHuntConfig?.stickerWord ?? 'QR Code';
            this.structureConfig.stickerWordPlural = this.codeHuntConfig?.stickerWordPlural ?? 'QR Codes';
            this.structureConfig.demonstrativeAdjective = this.codeHuntConfig?.demonstrativeAdjective ?? 'ce';
        }

        this.setupHeaderBar();

        if (!this.codeHuntConfig?.totalCodes) {
            await this.getAdditionnalConfig();
        }


        let needSave = false;

        if (this.codeHuntConfig?.codes?.length > 0 && this.codeHuntConfig?.codes?.some(code => code?.status === undefined)) {
            this.codeHuntConfig.codes = this.codeHuntConfig.codes.map(code => {
                return {
                    ...code,
                    status: code?.status ?? BadgeStatus.ACTIVE,
                };
            });
            needSave = true;
        }

        if (!this.codeHuntConfig?.baseCodes || this.codeHuntConfig.baseCodes?.length <= 0) {
            if (this.codeHuntConfig?.codes && this.codeHuntConfig?.codes?.length > 0) {
                this.codeHuntConfig.baseCodes = this.codeHuntConfig.codes;

                if (this.codeHuntConfig?.baseCodes?.length < this.codeHuntConfig?.totalCodes) {
                    this.codeHuntConfig.baseCodes = this.generateListOfCodes(this.codeHuntConfig.totalCodes - this.codeHuntConfig.baseCodes.length);
                }
            } else {
                this.codeHuntConfig.baseCodes = this.generateListOfCodes(this.codeHuntConfig.totalCodes);
            }
            needSave = true;
        }

        if (needSave) {
            this.saveConfig();
        }


        if (!Object.keys(this.codeHuntConfig)?.includes('structureUpdated')) {
            this.codeHuntConfig.structureUpdated = false;
        } else if (this.codeHuntConfig.structureUpdated === true) {
            this.stepsService.currentStepIdx = 1;
        }

        if (this.editorModeChanged) {
            this.editorModeChangedSubscription = this.editorModeChanged.subscribe(event => {
                // if (event.initiator === 'parent') return;

                this.editorMode = event.state;
            });
        }

        setTimeout(() => {
            this.waitingAnimation = true;
        }, 1000);

        // TODO: enlever cette ligne
        // setTimeout(() => {
            // if (environment.production === false) {
            //     this.stepsService.currentStepIdx = 3;
            // }
        // }, 100);
    }

    ionViewWillEnter() {
        this.setupHeaderBar();
    }


    // DESTROY

    ngOnDestroy() {
        this.stepsService.currentStepIdx = 0;
        this.resetHeaderBar();
        if (this.saveSubscription) this.saveSubscription.unsubscribe();
    }

    ionViewWillLeave() {
        this.stepsService.currentStepIdx = 0;
        this.resetHeaderBar();
    }


    /* ----------- Header Bar ----------- */

    resetHeaderBar() {
        this.headerBarService.onHeaderBarChanged.next({
            ...this.headerBarService.onHeaderBarChanged.value,
            stepsConfig: null
        });
    }

    setupHeaderBar() {
        const steps: any = [
            {
                label: 'Structure',
                checkClickable: this.isStructureClickable,
            },
            {
                label: this.isMultiQuiz ? 'Édition des quiz' : 'Édition de QR Codes',
                clickable: false,
                checkClickable: this.isConfigClickable,
                permanentStatus: this.isBaseConfigCompleted() ? 'completed' : 'waiting',
            }
        ];

        if (this.languages?.length > 1) {
            const step: StepItem = {
                label: `Structure multilingue`,
                checkClickable: this.isStructureMultilingualClickable,
                permanentStatus: this.isStructureMultilingualCompleted() ? 'completed' : 'waiting',
            };

            steps.push(step);

            this.languages?.forEach(lang => {
                if (lang === 'fr') return;

                const stepLang: StepItem = {
                    label: `Édition de${this.isMultiQuiz ? 's quiz' : ' QR Codes'}  en ${this.getLabelForLanguage(lang)}`,
                    checkClickable: this.isConfigMultilingualClickable,
                    language: lang,
                    isSmall: true,
                    permanentStatus: this.isLanguageCompleted(lang) ? 'completed' : 'waiting',
                };

                steps.push(stepLang);
            });
        }

        this.headerBarService.onHeaderBarChanged.next({
            ...this.headerBarService.onHeaderBarChanged.value,
            stepsConfig: {
                steps,
                width: 'fit-content',
            }
        });
    }

    isStructureMultilingualCompleted() {
        let returnValue = true;
        let baseConfig = null;

        if (this.configByLang[this.baseLanguage]?.content && this.configByLang[this.baseLanguage]?.content?.length > 0) {
            baseConfig = this.configByLang[this.baseLanguage]?.content[this.configByLang[this.baseLanguage]?.content?.length - 1]?.CodeHunt;
        } else {
            baseConfig = this.configByLang[this.baseLanguage];
        }

        this.languagesWithoutBase.forEach(lang => {
            if (!this.configByLang[lang]) returnValue = false;

            const hasSearchArea = !this.isMultiQuiz && baseConfig?.searchArea !== null && baseConfig?.searchArea !== undefined && baseConfig?.searchArea !== '';
            const hasStickerWord = !this.isMultiQuiz && baseConfig?.stickerWord !== this.newCodeHuntConfiguration?.stickerWord;
            const hasStickerWordPlural = !this.isMultiQuiz && baseConfig?.stickerWordPlural !== this.newCodeHuntConfiguration?.stickerWordPlural;
            const hasDemonstrativeAdjective = !this.isMultiQuiz && baseConfig?.demonstrativeAdjective !== this.newCodeHuntConfiguration?.demonstrativeAdjective;

            if (returnValue && this.modularConfigByLang[lang]?.title === null) returnValue = false;


            if (returnValue && this.configByLang[lang]?.introText === null || this.configByLang[lang]?.introText === this.newCodeHuntConfiguration?.introText) returnValue = false;


            if (returnValue && hasSearchArea && (!this.configByLang[lang].searchArea || this.configByLang[lang]?.searchArea === null)) returnValue = false;
            if (returnValue && hasStickerWord && (this.configByLang[lang]?.stickerWord === null || this.configByLang[lang]?.stickerWord === this.newCodeHuntConfiguration?.stickerWord)) returnValue = false;
            if (returnValue && hasStickerWordPlural && (this.configByLang[lang]?.stickerWordPlural === null || this.configByLang[lang]?.stickerWordPlural === this.newCodeHuntConfiguration?.stickerWordPlural)) returnValue = false;
            if (returnValue && hasDemonstrativeAdjective && (this.configByLang[lang]?.demonstrativeAdjective === null || this.configByLang[lang]?.demonstrativeAdjective === this.newCodeHuntConfiguration?.demonstrativeAdjective)) returnValue = false;
        });

        return returnValue;
    }

    isLanguageCompleted(lang: string) {
        if (this.isMultiQuiz) return this.isLanguageCompletedMultiQuiz(lang);

        let configBaseLanguage = null;
        if (this.configByLang[this.baseLanguage]?.codes?.length > 0) {
            configBaseLanguage = JSON.parse(JSON.stringify(this.configByLang[this.baseLanguage]));
        } else if (this.configByLang[this.baseLanguage]?.content?.length > 0) {
            configBaseLanguage = JSON.parse(JSON.stringify(this.configByLang[this.baseLanguage]?.content[0]?.CodeHunt));
        }

        if (this.configByLang[lang]?.codes?.length <= 0) return false;

        let returnValue = true;

        // on check si le code est complet
        configBaseLanguage?.codes?.forEach((code: CodeHuntCode) => {
            if (!returnValue) return;
            const codeLang = this.configByLang[lang]?.codes?.find((c: CodeHuntCode) => c.code === code.code);

            if (!codeLang) returnValue = false;

            // on vérifie s'il a un indice
            if (returnValue && code?.hint?.content !== null && code?.hint?.content?.length > 0 && (codeLang?.hint?.content === null || codeLang?.hint?.content?.length <= 0)) returnValue = false;

            // on vérifie s'il a le même nombre de questions de subExp
            if (returnValue && code?.subExpConfig && code?.subExpConfig?.length > 0 && codeLang?.subExpConfig && codeLang?.subExpConfig?.length > 0 && code?.subExpConfig?.length !== codeLang?.subExpConfig?.length) returnValue = false;

            // on vérifie si les questions sont traduites
            if (returnValue && code?.subExpConfig && code?.subExpConfig?.length > 0) {
                code.subExpConfig.forEach((subExp: any, subExpIdx) => {
                    if (!returnValue) return;

                    if (!codeLang?.subExpConfig || codeLang?.subExpConfig?.length <= subExpIdx || !codeLang?.subExpConfig[subExpIdx]) {
                        returnValue = false;
                        return;
                    }

                    const subExpLang = codeLang?.subExpConfig[subExpIdx]?.SimpleQuiz;

                    if (!subExpLang) returnValue = false;

                    // si la question de subExp est encore en toTranslate === true -> à traduire
                    if (subExpLang?.toTranslate === true) returnValue = false;

                    // on check s'il y a bien un contenu de question
                    if (!subExpLang?.question || subExpLang?.question === null) returnValue = false;

                    // on check s'il y a bien un contenu d'explication
                    // if (subExp?.SimpleQuiz?.explanation && (!subExpLang?.explanation || subExpLang?.explanation === null)) returnValue = false;

                    // on check s'il y'a des réponses
                    if (!subExpLang?.answers || subExpLang.answers?.length <= 0) returnValue = false;
                    // s'il y a des réponses, on check si elles sont complètes
                    else {
                        subExpLang.answers.forEach((answer: any, answerIdx) => {
                            if (!answer?.answer || answer.answer?.length === 0) returnValue = false;
                        });
                    }
                });
            }
        });

        return returnValue;
    }

    isLanguageCompletedMultiQuiz(lang: string) {
        if (this.configByLang[lang]?.codes?.length <= 0) return false;

        let returnValue = true;

        let configBaseLanguage = null;
        if (this.configByLang[this.baseLanguage]?.codes?.length > 0) {
            configBaseLanguage = JSON.parse(JSON.stringify(this.configByLang[this.baseLanguage]));
        } else if (this.configByLang[this.baseLanguage]?.content?.length > 0) {
            configBaseLanguage = JSON.parse(JSON.stringify(this.configByLang[this.baseLanguage]?.content[0]?.CodeHunt));
        }

        // on check si le code est complet
        configBaseLanguage?.codes?.forEach((code: CodeHuntCode) => {
            if (!returnValue) return;

            const codeLang = this.configByLang[lang]?.codes?.find((c: CodeHuntCode) => c.code === code.code);

            if (!codeLang) returnValue = false;
            else {
                this.configByLang[lang]?.codes?.forEach((codeToCheck: CodeHuntCode) => {
                    if (codeToCheck?.code !== codeLang.code && codeToCheck?.label?.toLowerCase()?.trim() === codeLang.label?.toLowerCase()?.trim()) {
                        returnValue = false;
                    }
                });
            }

            // on vérifie s'il a un titre
            if (returnValue && code?.label && code?.label?.length > 0 && (!codeLang?.label || codeLang?.label?.length <= 0)) returnValue = false;

            // on vérifie s'il a des questions
            if (returnValue && code?.subExpConfig && code?.subExpConfig?.length > 0 && codeLang?.subExpConfig && codeLang?.subExpConfig?.length > 0 && code?.subExpConfig?.length !== codeLang?.subExpConfig?.length) returnValue = false;

            // on vérifie si les questions sont traduites
            if (returnValue && code?.subExpConfig && code?.subExpConfig?.length > 0) {
                code.subExpConfig.forEach((subExp: any, subExpIdx) => {
                    if (!returnValue) return;

                    let subExpTempBase = null;
                    if (subExp?.SimpleQuiz) {
                        subExpTempBase = JSON.parse(JSON.stringify(subExp?.SimpleQuiz));
                    } else {
                        subExpTempBase = JSON.parse(JSON.stringify(subExp));
                    }

                    let subExpLang = null;
                    if (codeLang?.subExpConfig && codeLang?.subExpConfig?.length > subExpIdx) {
                        subExpLang = codeLang?.subExpConfig[subExpIdx]?.SimpleQuiz;
                    }

                    if (!subExpTempBase || !subExpLang) returnValue = false;

                    // si la question de subExpTemp est encore en toTranslate === true -> à traduire
                    if (subExpLang?.toTranslate === true) returnValue = false;

                    // on check s'il y a bien un contenu de question
                    if (!subExpLang?.question || subExpLang?.question === null) returnValue = false;

                    // on check s'il y'a des réponses
                    if (!subExpLang?.answers || subExpLang.answers?.length <= 0) returnValue = false;
                    // s'il y a des réponses, on check si elles sont complètes
                    else {
                        subExpLang.answers.forEach((answer: any, answerIdx) => {
                            if (!answer?.answer || answer.answer?.length === 0) returnValue = false;
                        });
                    }
                });
            }
        });

        return returnValue;
    }

    isBaseConfigCompleted() {
        let returnValue = true;

        let config = null;

        if (this.configByLang[this.baseLanguage]?.codes?.length > 0) {
            config = JSON.parse(JSON.stringify(this.configByLang[this.baseLanguage]));
        } else if (this.configByLang[this.baseLanguage]?.content?.length > 0 && this.configByLang[this.baseLanguage]?.content[0]?.CodeHunt && this.configByLang[this.baseLanguage]?.content[0]?.CodeHunt?.codes?.length > 0) {
            config = JSON.parse(JSON.stringify(this.configByLang[this.baseLanguage]?.content[0]?.CodeHunt));
        }

        if (!config || !config?.codes || config?.codes?.length <= 0) return false;

        if (!this.isMultiQuiz) return true;

        // on boucle sur tous les codes pour checker si tout est complet
        config?.codes?.forEach((code: CodeHuntCode) => {
            if (!returnValue) return;

            config?.codes?.forEach((codeToCheck: CodeHuntCode) => {
                if (codeToCheck?.code !== code.code && codeToCheck?.label?.toLowerCase()?.trim() === code.label?.toLowerCase()?.trim()) {
                    returnValue = false;
                }
            });

            // on vérifie s'il a un titre
            if (returnValue && code?.label === null || code?.label === '') returnValue = false;

            // on vérifie s'il a le même nombre de questions de subExp
            if (returnValue && (!code?.subExpConfig || code?.subExpConfig?.length <= 0)) returnValue = false;

            // on vérifie si les questions sont traduites
            if (returnValue && code?.subExpConfig && code?.subExpConfig?.length > 0) {
                code.subExpConfig.forEach((subExp: any, subExpIdx) => {
                    if (!returnValue) return;

                    const subExpLang = subExp?.SimpleQuiz;

                    if (!subExpLang) returnValue = false;

                    // on check s'il y a bien un contenu de question
                    if (!subExpLang?.question || subExpLang?.question === null || subExpLang?.question === '') returnValue = false;

                    // on check s'il y'a des réponses
                    if (!subExpLang?.answers || subExpLang.answers?.length <= 0) returnValue = false;
                    // s'il y a des réponses, on check si elles sont complètes
                    else {
                        subExpLang.answers.forEach((answer: any, answerIdx) => {
                            if (!answer?.answer || answer.answer?.length === 0) returnValue = false;
                        });
                    }
                });
            }
        });

        return returnValue;
    }

    getLabelForLanguage(lang: string) {
        if (lang === 'fr') return 'français';
        if (lang === 'en' || lang === 'gb') return 'anglais';
        if (lang === 'de') return 'allemand';
    }

    isStructureClickable = () => {
        return this.onConfigClickable('structure');
    };

    isStructureMultilingualClickable = () => {
        return this.onConfigClickable('structure-language');
    };

    isConfigClickable = () => {
        return this.onConfigClickable('content');
    };

    isConfigMultilingualClickable = (lang) => {
        return this.onConfigClickable('content-language', lang);
    };

    onConfigClickable(key: 'structure' | 'content' | 'structure-language' | 'content-language', language: string = null) {
        this.configByLang[this.baseLanguage] = this.codeHuntConfig;

        let langIdx = -1;
        if (key === 'content-language') {
            langIdx = this.languages.indexOf(language);
        }

        if (this.changesSubject.value === true) {
            this.openModalPendingChanges(key, langIdx);
            return false;
        }

        if (this.stepsService.currentStepIdx === 0 && key === 'structure') return true;
        if (this.stepsService.currentStepIdx === 1 && key === 'content') return true;

        this.editorMode = false;

        this.undoDisabled = true;
        this.redoDisabled = true;

        return true;
    }



    /* ----------- Sauvegarde ----------- */


    async saveConfig() {
        const configFinal = this.config?.modularConfig;
        configFinal.content[configFinal?.content?.length - 1].CodeHunt = this.codeHuntConfig;

        try {
            await firstValueFrom(this.orderService.configureGenericExperience(this.orderId, { ModularRoot: configFinal }));
        } catch (error) {
            throw error;
        }
    }

    /* ----------- Auto Save ----------- */

    autoSavingTriggered(event: { state: boolean; key?: string; config?: any; language?: string }) {
        if (event?.state === true) {
            this.isAutoSaving = true;
        } else if (event?.state === false) {
            if (this.isAutoSaving === true) {
                this.hasAutoSaved = true;
                setTimeout(() => {
                    this.hasAutoSaved = false;
                }, 2000);
            }
            this.isAutoSaving = false;
        } else if (event?.state === null) {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de la sauvegarde automatique.' });
        }

        if (event?.config) {
            if (event?.key === 'structure') {
                this.structureConfig = event.config;
            } else if (event?.key === 'content') {
                if (event?.language === this.baseLanguage) {
                    this.codeHuntConfig.codes = JSON.parse(JSON.stringify(event.config));
                }
                this.configByLang[event?.language].codes = JSON.parse(JSON.stringify(event.config));
            }
        }

        this.setupHeaderBar();
    }

    /* ----------- Navigation ----------- */

    onPrevClick() {
        this.stepsService.previousStep();
    }
    onNextClick() {
        this.stepsService.nextStep();
    }


    /* ----------- Structure ----------- */

    generateListOfCodes(count: number): CodeHuntCode[] {
        const codes: Set<string> = new Set();
        const newCodes: CodeHuntCode[] = [];
        let maxOverlap = 0;

        if (count <= 0) return null;

        // Generate the specified number of unique codes
        while (codes.size < count && maxOverlap < 1000) {
            codes.add(this.generateRandomCode());
            maxOverlap++;
        }

        Array.from(codes)?.forEach(newCode => {
            newCodes.push({ code: newCode, points: this.codeHuntConfig?.codesPoints ?? 1, visible: true });
        });

        newCodes?.sort((a, b) => a.code.localeCompare(b.code));

        newCodes?.forEach((code, codeIdx) => {
            console.log(codeIdx, code.code);
        });

        // Convert the set to an array and return it
        return newCodes;
    }

    generateRandomCode(): string {
        const letters = 'ABCDEFGHJKMNPQRSTUVWXYZ';
        const digits = '0123456789';

        // Generate two random letters
        let letterPart = letters.charAt(Math.floor(Math.random() * letters.length)) +
            letters.charAt(Math.floor(Math.random() * letters.length));

        // Generate three random digits
        let digitPart = digits.charAt(Math.floor(Math.random() * digits.length)) +
            digits.charAt(Math.floor(Math.random() * digits.length)) +
            digits.charAt(Math.floor(Math.random() * digits.length));

        let maxOverlap = 0;
        while (maxOverlap < 100 && (letterPart === 'HH' || letterPart === 'SS' || letterPart === 'PD')) {
            letterPart = letters.charAt(Math.floor(Math.random() * letters.length)) +
                letters.charAt(Math.floor(Math.random() * letters.length));
            maxOverlap++;
        }

        maxOverlap = 0;
        while (maxOverlap < 100 && (digitPart === '666' || digitPart === '333')) {
            digitPart = digits.charAt(Math.floor(Math.random() * digits.length)) +
                digits.charAt(Math.floor(Math.random() * digits.length)) +
                digits.charAt(Math.floor(Math.random() * digits.length));
            maxOverlap++;
        }

        // Combine and return the code
        return letterPart + digitPart;
    }

    async getAdditionnalConfig() {
        try {
            const res: any = await firstValueFrom(this.orderService.getSubscribedOrderOptions(this.authService.userLogged.value?.id, this.orderId));

            if (res?.options?.stickerOptions[0]?.values?.count) {
                this.codeHuntConfig.totalCodes = res?.options?.stickerOptions[0]?.values?.count;
                await this.saveConfig();
            }

            this.checkMultiCategories(res);

        } catch (error) {
            console.log('getAdditionnalConfig error', error);
        }
    }

    checkMultiCategories(res: any) {
        if (Object.keys(res?.options).includes('multi-categories') && !this.codeHuntConfig.hasMultiCategories) {
          this.codeHuntConfig.hasMultiCategories = true;
          if (!Object.keys(this.codeHuntConfig).includes('multiCategories')) {
            this.codeHuntConfig.multiCategories = [];
          }
        }
      }

    async openModalPendingChanges(key: 'structure' | 'content' | 'structure-language' | 'content-language', languageIdx: number) {
        const modal = await this.modalController.create({
            component: ModalIonicConfirmPrimeComponent,
            cssClass: 'modal-login',
            backdropDismiss: false,
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned?.data === 'leave') {
                this.undoDisabled = true;
                this.redoDisabled = true;
                this.editorMode = false;
                this.editorModeChanged.next({state: false, initiator: 'parent'});
                this.editorModeChanged.next({state: false, initiator: 'child'});
                if (key === 'structure') {
                    this.stepsService.currentStepIdx = 0;
                } else if (key === 'content') {
                    this.stepsService.currentStepIdx = 1;
                } else if (key === 'structure-language') {
                    this.stepsService.currentStepIdx = 2;
                } else if (key === 'content-language') {
                    this.stepsService.currentStepIdx = 2 + languageIdx;
                }
                this.changesSubject.next(false);
            }
        });

        return await modal.present();
    }

    toggleEditorMode() {
        this.editorModeChanged.next({state: false, initiator: 'parent'});
    }

    async getConfigByLang(lang: string) {
        try {
            const res: any = await firstValueFrom(this.orderService.getOrderConfiguration(this.orderId, lang));

            // this.config = res;
            if (res?.configuration?.ModularRoot?.content) {
                const newConfig = res?.configuration?.ModularRoot?.content[res?.configuration?.ModularRoot?.content?.length - 1]?.CodeHunt;
                newConfig.baseCodes = this.codeHuntConfig?.baseCodes;
                newConfig.totalCodes = this.codeHuntConfig?.totalCodes;

                this.codeHuntConfig?.codes?.forEach((baseCode: CodeHuntCode) => {
                    if (!(newConfig?.codes as CodeHuntCode[]).some((code: CodeHuntCode) => code.code === baseCode.code)) {
                        const newCode: CodeHuntCode = {
                            code: baseCode.code,
                            points: baseCode.points,
                            hint: {
                                index: null,
                                content: null,
                                location: null
                            }
                        };
                        newCode.subExpConfig = null;
                        newCode.status = baseCode?.status;

                        if (baseCode?.hint?.location) {
                            newCode.hint = {
                                content: null,
                                index: baseCode.hint.index,
                                location: baseCode.hint.location
                            };
                        }
                        newConfig?.codes?.push(newCode);
                    }
                });

                this.configByLang[lang] = JSON.parse(JSON.stringify(newConfig));
                this.modularConfigByLang[lang] = res?.configuration?.ModularRoot?.configuration;
            }
        } catch (error) {
            console.log('error on getConfigurationByLang:', lang, error);
        }
    }

    async getPinCode() {
        if (!this.authService?.userLogged?.value?.id) {
            return;
        }

        try {
            const order = await firstValueFrom(this.orderService.getOrderById(this.authService.userLogged.value.id, this.orderId));

            if (!order) return;

            const dateNow = DateTime.now();

            // check if order?.experiencePlanning?.startDate is after now
            if (order?.experiencePlanning?.startDate && (DateTime.fromISO(order?.experiencePlanning?.startDate as any) < dateNow)) {
                this.pin = null;
                return;
            }

            const res = await firstValueFrom(this.clientExperienceService.getPinCode(this.authService.userLogged.value.id, this.orderId));

            if (res?.code) {
                this.pin = res.code;
            }
        } catch (error) {
            console.log('error on getPinCode', error);
        }
    }
}
